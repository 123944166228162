.notifications {
    position: absolute;
    top: 137%;
    background-color: white;
    border-radius: 20px;
    padding: 20px 25px;
    right: -137px;
    box-shadow: 0 0 20px 0 rgba(black, 0.2);
    z-index: 1000;
    width: 350px;
    font-size: 16px;
    cursor: default;
    @media screen and (min-width: 992px) and (max-width: 1499px) {
        right: -60px;
    }
    @include media-breakpoint-down(md) {
        width: 330px;
        right: -110px;
    }
    &:before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 30px 25px 30px;
        border-color: transparent transparent #ffffff transparent;
        position: absolute;
        top: -25px;
        left: 50%;
        transform: translateX(-50%);
        @media screen and (min-width: 992px) and (max-width: 1499px) {
            border-width: 0 20px 15px 20px;
            top: -15px;
            left: auto;
            transform: none;
            right: 72px;
        }
    }
    &-header {
        border-bottom: 1px solid rgba(128, 128, 128, 0.3);
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        padding-bottom: 15px;
        text-transform: none;
        &-text {
            position: relative;
            display: inline-block;
            span {
                display: inline-block;
                background-color: red;
                color: white;
                width: 20px;
                height: 20px;
                line-height: 20px;
                font-size: 12px;
                font-weight: 700;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    &-list {
        text-align: left;
        .notification {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            padding: 15px 0;
            border-bottom: 1px solid rgba(128, 128, 128, 0.3);
            &-icon {
                padding-right: 15px;
                flex: 0 0 60px;
                max-width: 60px;
                span {
                    background-color: rgba(128, 128, 128, 0.3);
                    color: $color-orange;
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 40px;
                    font-size: 17px;
                }
            }
            &-text {
                flex: 0 0 calc(100% - 60px);
                max-width: calc(100% - 60px);
                font-weight: 400;
                text-transform: none;
                font-size: 13px;
                a {
                    font-weight: 700;
                }
                &-time {
                    margin-top: 5px;
                    color: $color-orange;
                }
            }
            &:last-of-type {
                border-bottom: 0;
            }
        }
    }
    &-footer {
        border-top: 1px solid rgba(128, 128, 128, 0.3);
        padding-top: 15px;
        text-align: center;
        .btn {
            font-size: 14px;
        }
    }
}
.notification-info {
    position: absolute;
    width: 20px;
    height: 20px;
    line-height: 20px;
    color: white;
    background-color: red;
    border-radius: 50%;
    text-align: center;
    top: -6px;
    left: -6px;
    font-size: 10px;
}
