.file-locations{
    li{
        border-bottom: 1px solid #dee2e6;
        i,svg{
            color:$color-light-blue;
            margin-right: 10px;
        }
        &:last-of-type{
            border-bottom: 0;
        }
    }
}