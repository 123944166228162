.event-questions {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1.5%;
    .question-data-show {
        flex: 0 0 calc((100% - 3%) / 3);
        max-width: calc((100% - 3%) / 3);
        margin-bottom: 25px;
        .card-body {
            padding-right: 45px;
        }
        &-title {
            color: $primary;
            font-weight: 700;
            font-size: 20px;
            margin-bottom: 10px;
            min-height: 60px;
        }
        .btns {
            position: absolute;
            right: 20px;
            top: 25px;
            display: flex;
            gap: 1rem;
        }
    }
}
