.user-selection {
    &-input {
        margin-bottom: 10px;
        border: 1px solid rgba($primary, 0.35);
        padding: 15px;
    }
}

.parent-user-selection {
    border: 1px solid rgba($primary, 0.35);
    margin-bottom: 10px;
    padding: 15px;
    ul{
        margin:0;
    }
}
