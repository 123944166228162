.matching {
    &-search {
        h1 {
            text-align: center;
            color: $primary;
            text-transform: uppercase;
            margin-bottom: 20px;
            font-weight: 700;
            @include media-breakpoint-down(lg) {
                font-size: 18px;
            }
        }
        &-inner {
            position: relative;
            input {
                width: 100%;
                max-width: 100%;
                background-color: white;
                color: $primary;
                border: 1px solid white;
                border-radius: 100px;
                font-weight: 500;
                font-size: 18px;
                padding: 15px 35px;
                text-transform: uppercase;
                margin: 0;
                box-shadow: 0 0 10px 0 rgba(black, 0.2);
                height: auto;
                @media screen and (min-width: 992px) and (max-width: 1450px){
                    font-size: 16px;
                }
                @include media-breakpoint-down(lg) {
                    font-size: 16px;
                    padding: 10px 18px;
                    margin-bottom: 15px;
                }
            }
            ::placeholder {
                color: $primary;
            }
            button {
                background-color: $color-light-blue;
                color: white;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                border-radius: 100px;
                border: 2px solid $color-light-blue;
                font-size: 18px;
                font-weight: 600;
                padding-left: 40px;
                padding-right: 40px;
                transition: 0.3s ease all;
                @media screen and (min-width: 992px) and (max-width: 1450px){
                    font-size: 16px;
                }
                @include media-breakpoint-down(lg) {
                    position: relative;
                    width: 100%;
                    font-size: 16px;
                    padding: 8px 18px;
                }
                i,
                svg {
                    margin-left: 25px;
                }
                &:hover {
                    background-color: white;
                    color: $color-light-blue;
                }
            }
        }
    }
}
