.user-show-page {
    .logo-with-text {
        display: flex;
        gap: 8px;
        .logo {
            flex-grow: 0;
            aspect-ratio: 1;
        }
        > * {
            flex-grow: 1;
        }
    }
}
