.chat-list {
    &-entry {
        padding: 20px 15px;
        border-bottom: 1px solid rgba(128, 128, 128, 0.3);
        width: 420px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        font-size: 14px;
        transition: 0.3s ease all;
        @media screen and (min-width:1200px) and (max-width:1560px){
            width: 350px;
        }
        @include media-breakpoint-down(md){
            padding:12px;
            width:100%;
        }
        &.highlighted{
            background-color: rgba($color-light-blue,0.1);
            .chat-list-entry-info-name{
                font-weight: 700;
            }
        }
        &-avatar {
            padding-right: 20px;
            flex: 0 0 65px;
            max-width: 65px;
            @include media-breakpoint-down(md){
                flex: 0 0 50px;
                max-width: 50px;
            }
        }
        &-info {
            flex: 0 0 calc(100% - 65px);
            max-width: calc(100% - 65px);
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            @include media-breakpoint-down(md){
                align-content: center;
            }
            &-name {
                flex: 0 0 calc(100% - 80px);
                max-width: calc(100% - 80px);
                font-weight: 500;
                // @include media-breakpoint-down(md){
                //     flex: 0 0 calc(100% - 50px);
                //     max-width: calc(100% - 50px);
                // }
            }
            &-date {
                flex: 0 0 80px;
                max-width: 80px;
                .badge {
                    width: 80px;
                }
            }
            &-excerpt {
                color: #5e5e5e;
                margin-top: 10px;
                width: 100%;
                font-size: 13px;
                @include media-breakpoint-down(md){
                    display: none;
                }
            }
        }
        &:hover {
            cursor: pointer;
            background-color: rgba(128, 128, 128, 0.2);
        }
        &.active {
            background-color: rgba(128, 128, 128, 0.2);
        }
    }
}
