.thread-creator{
    background-color: #f1f9fb;
    display: inline-block;
    color:$primary;
    font-size: 14px;
    line-height: 1;
    padding:11px 20px;
    border-radius: 40px;
    padding-left:53px;
    position: relative;
    @media screen and (max-width:1600px) and (min-width:992px) {
        line-height: 1.1;
    }
    .avatar{
        position: absolute;
        left:0;
        top:50%;
        transform: translateY(-50%);
    }
    @media screen and (min-width:992px) and (max-width:1500px){
        a{
            font-size: 12px;
        }
    }
    @include media-breakpoint-down(md){
        &>a{
            display: none;
        }
    }
}