.chat-show {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    height: 100%;
    position: relative;
    align-content: flex-start;
    &-detail {
        background-color: white;
        padding: 25px;
        box-shadow: 0 0 10px 0 rgba(black, 0.15);
        flex: 0 0 100%;
        height: 80px;
        @media screen and (min-width: 992px) and (max-width: 1450px) {
            padding: 10px;
            height: 50px;
        }
        @include media-breakpoint-down(sm) {
            padding: 15px;
            height: auto;
        }
        &-name {
            font-size: 18px;
            font-weight: 500;
        }
        @include media-breakpoint-down(md) {
            & > .row {
                align-items: center;
            }
        }
    }
    .sidebar-trigger {
        font-size: 18px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        line-height: 30px;
        text-align: center;
        position: relative;
        display: inline-block;
        background-color: #cceaf0;
        border: 1px solid #cceaf0;
        cursor: pointer;
        transition: 0.3s ease all;
        &:hover {
            background-color: white;
            color: $primary;
        }
    }
    .page-wrapper {
        flex: 0 0 100%;
        height: calc(100% - 250px);
        display: flex;
        // align-items: flex-end;
        min-height: auto;
        max-width: 100%;
        @media screen and (min-width: 992px) and (max-width: 1450px) {
            padding: 1rem 2rem;
        }
    }
    .chat-box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-end;
        &-editor {
            flex: 0 0 100%;
            border-top: 1px solid rgba(128, 128, 128, 0.45);
            padding-top: 25px;
            margin-top: 40px;
            @media screen and (min-width: 992px) and (max-width: 1450px) {
                margin-top: 15px;
                padding-top: 10px;
            }
            &-actions {
                margin-bottom: 20px;
                .btn {
                    font-size: 20px;
                    margin-right: 25px;
                }
            }
            &-message {
                display: flex;
                flex-direction: row;
            }
            &-files {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 10px;
                .file-item {
                    display: flex;
                    gap: 5px;
                    align-items: center;
                }
                img {
                    width: 30px;
                    height: 30px;
                    border-radius: 999999px;
                }
            }
            &-text {
                flex: 1 1 auto;
                padding-right: 25px;
                textarea {
                    width: 100%;
                    border: 0;
                    background-color: transparent;
                    box-shadow: none;
                    outline: none;
                    max-height: 60px;
                    overflow: auto !important;
                }
            }
            &-submit {
                flex: 0 0 auto;
                @include media-breakpoint-down(md) {
                    .btn {
                        font-size: 14px;
                        padding: 10px 18px;
                    }
                }
            }
        }
    }
}
