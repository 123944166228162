.list-table {
  .file-list-name-column{
    max-width: 30%;
    overflow-wrap: anywhere;
  }
  margin-bottom: 0;
  thead {
    th,
    th > .unstyled-btn {
      border: 0;
      padding-top: 0;
      padding-left: 0;
      font-size: 14px;
      font-weight: 700;
      color: $primary;
      text-transform: uppercase;
    }
    th {
      position: relative;
      i,
      svg {
        position: absolute;
        right: 25px;
        top: 4px;
      }
    }
  }
  tbody {
    td {
      padding-left: 0;
    }
    .thread {
      td {
        padding-left: 0;
        vertical-align: middle;
        & > svg,
        & > i,
        & > .unstyled-btn {
          color: $color-orange;
          font-size: 18px;

          @media screen and (min-width: 992px) and (max-width: 1450px) {
            font-size: 14px;
          }
        }
        & > a {
          & > svg,
          & > i {
            color: $color-light-blue;
            font-size: 24px;
            @media screen and (min-width: 992px) and (max-width: 1450px) {
              font-size: 20px;
            }
          }
        }
        &.thread-detail {
          .thread-detail-name {
            color: $primary;
            font-size: 18px;
            font-weight: 700;
            @media screen and (min-width: 992px) and (max-width: 1450px) {
              font-size: 16px;
            }
            @include media-breakpoint-down(md) {
              font-size: 16px;
            }
          }
          .thread-detail-table {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            margin-top: 4px;
            div {
              font-size: 14px;
              color: #909090;
              font-weight: 400;
              padding-right: 15px;
              @media screen and (min-width: 1200px) and (max-width: 1450px) {
                font-size: 12px;
              }
              i,
              svg {
                margin-right: 5px;
                color: $color-orange;
              }
            }
          }
        }
      }
      &.member {
        td {
          color: $primary;
        }
        .date {
          font-weight: 500;
          i,
          svg {
            margin-right: 10px;
            color: $color-orange;
          }
        }
        .thread-creator {
          background-color: transparent;
        }
        .thread-membership {
          display: flex;
          align-items: center;
          span.membership-state {
            background-color: #47d023;
            color: white;
            width: 24px;
            height: 24px;
            display: inline-block;
            border-radius: 50%;
            text-align: center;
            padding: 0;
            line-height: 25px;
            font-size: 10px;
            margin-right: 15px;
            font-weight: 700;
            &.yellow {
              background-color: #fcac13;
            }
            &.red {
              background-color: $color-red;
            }
            &.blue {
              background-color: $color-light-blue;
            }
          }
        }
      }
    }
  }
  &-detail-icon {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #909090;
    i,
    svg {
      margin-right: 10px;
    }
  }
  &.list-table-small {
    @media screen and (min-width: 992px) and (max-width: 1450px) {
      th {
        font-size: 14px;
      }
    }
    tbody {
      td {
        font-size: 16px;
        font-weight: 400;
        padding: 0.5rem 0.75rem;
        padding-left: 0;
        @media screen and (min-width: 992px) and (max-width: 1450px) {
          font-size: 14px;
        }
      }
    }
  }
  .table-remove-button {
    background: transparent;
    background-image: none;
    border: 0;
    padding: 0;
    color: red;
    font-size: 24px;
    margin-right: 15px;
  }
}

.table {
  @include media-breakpoint-down(md) {
    max-width: 100%;
    overflow-x: auto;
  }
  .td-buttons {
    button {
      margin-right: 6px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  &.table-hover {
    @media screen and (min-width: 992px) and (max-width: 1450px) {
      font-size: 14px;
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1450px) {
    td,
    th {
      font-size: 14px;
    }
  }
}

.price-table {
  tr {
    th,
    td {
      padding-right: 15px;
    }
  }
}

.report-table {
  tbody {
    tr {
      &:nth-child(even) {
        background-color: rgba(grey, 0.2);
      }
      &:last-of-type {
        background-color: white;
        td {
          border-top: 1px solid black;
        }
      }
    }
  }
}
