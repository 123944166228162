.notification-display {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1.5%;
    @include media-breakpoint-down(sm) {
        gap: 0;
    }
    .notification {
        background-color: white;
        padding: 15px;
        padding-left: 10px;
        border-left: 5px solid transparent;
        border-radius: 6px;
        margin-bottom: 15px;
        flex: 0 0 calc((100% - 4.5%) / 4);
        box-shadow: $shadow-default;
        @include media-breakpoint-only(md) {
            flex: 0 0 calc((100% - 1.5%) / 2);
        }
        @include media-breakpoint-down(sm) {
            flex: 0 0 100%;
        }
        &.unread {
            border-left-color: #ffbf40;
        }
        &-icon {
            margin-bottom: 15px;
            span {
                width: 40px;
                height: 40px;
                display: inline-block;
                line-height: 40px;
                text-align: center;
                background-color: rgba($color-light-blue, 0.25);
                color: $primary;
                border-radius: 50%;
            }
        }
        &-text {
            .notification-text-time {
                margin-top: 10px;
            }
            .notification-chat-buttons {
                margin-top: 10px;
                .btn {
                    margin: 0 !important;
                }
            }
        }
    }
}
