.topic-wrapper{
    height:100%;
    overflow-y: scroll;
    .topic-list{
        padding:40px 50px;
        &-header{
            margin-bottom: 30px;
            .btn{
                margin-left:15px;
            }
        }
    }
    .topic-table-wrapper{
        background-color: white;
        padding:40px 35px;
        box-shadow: 0 0 12px 0 rgba(black,0.15);
        border-radius: 20px;
        .table{
            margin:0;
        }
    }
}

.topic-list-mobile{
    @include media-breakpoint-up(xl){
        display: none;
    }
    &-item{
        padding:10px 0;
        border-bottom: 1px solid rgba(grey,0.25);
        display:block;
        position: relative;
        .thread-creator{
            position: relative;
            margin-bottom: 10px;
        }
        &-title{
            display: block;
            font-weight: 700;
            margin-bottom: 10px;
            font-size: 18px;
            &-subline{
                display: block;
                font-weight: 400;
            }
        }
        &-actions{
            color:black;
            i,svg{
                color:$color-orange;
                margin-right: 10px;
            }
        }
        &-link{
            position: absolute;
            right:10px;
            bottom:10px;
            i,svg{
                color:$color-orange;
            }
        }
        &:last-of-type{
            border-bottom: 0;
        }
    }
}