.tabs{
    .nav-tabs{
        border:0;
        .nav-item{
            .nav-link{
                border:0;
                position: relative;
                padding-left:0;
                padding-right:0;
                margin-right: 25px;
                background-color: transparent;
                color:$primary;
                text-transform: uppercase;
                font-weight: 600;
                @media screen and (min-width: 992px) and (max-width: 1450px) {
                  font-size: 14px;
                }
                &:before{
                    content:'';
                    display: inline-block;
                    position: absolute;
                    left:0;
                    bottom:0;
                    height:2px;
                    background-color: $primary;
                    width:0;
                    transition: 0.3s ease all;
                }
                &.active,&:hover{
                    &:before{
                        width:100%;
                    }
                }
            }
        }
    }
    .tab-pane{
        margin-top:1rem;
    }
}