.event-sidebar{
    .card-title{
        color:$primary;
        font-size: 20px;
        font-weight: 700;
    }
    .event-preview{
        padding:15px 0;
        border-bottom: 1px solid lightgray;
        &-title{
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 8px;
        }
        &-details{
            display:flex;
            flex-wrap: wrap;
            flex-direction: row;
            gap:4%;
            i,svg{
                margin-right: 10px;
            }
            .badge{
                margin-bottom: 7px;
            }
        }
        // &-type, &-date, &-members{
        //     flex: 0 0 49%;
        //     max-width: 49%;
        // }
    }
}