.list-header {
  margin-bottom: 20px;
  h1 {
    font-size: 34px;
    font-weight: 700;
    color: $primary;
    line-height: 1.15;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    @media screen and (min-width: 992px) and (max-width: 1450px) {
      font-size: 22px;
      font-weight: 600;
      display: block;
    }
    @include media-breakpoint-down(md) {
      font-size: 23px;
      margin-bottom: 5px;
    }
    .avatar {
      margin-right: 20px;
    }
    & > div {
      display: inline-block;
      @include media-breakpoint-down(lg) {
        display: block;
      }
      i,
      svg {
        color: $color-orange;
        font-size: 24px;
        margin-left: 20px;
        @include media-breakpoint-down(md) {
          font-size: 18px;
          margin-left: 0;
          margin-right: 20px;
        }
      }
    }
    &.small-desktop-headline{
      @media screen and (min-width: 992px) and (max-width: 1450px){
        display: flex;
      }
    }
  }
  .btn-clean {
    margin-top: 15px;
    font-size: 20px;
    i,
    svg {
      color: $color-orange;
      font-size: 22px;
    }
  }
  &-results {
    @include media-breakpoint-down(md) {
      .badge {
        white-space: break-spaces;
        // word-break: break-all;
      }
    }
  }
  .btn {
    border-color: $color-orange;
    background-color: $color-orange;
    color: white;
    font-weight: 700;
    font-size: 14px;
    @media screen and (min-width: 992px) and (max-width: 1440px) {
      font-weight: 400;
      font-size: 12px;
    }
    @include media-breakpoint-down(md) {
      font-size: 13px;
    }
    i,
    svg {
      margin-right: 15px;
    }
    &:hover,
    &:focus {
      background-color: $primary;
      color: white;
      border-color: $primary;
    }
  }
  @include media-breakpoint-down(md) {
    .text-right {
      text-align: left !important;
    }
  }
}
