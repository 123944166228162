
#cookie-banner {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  backdrop-filter: blur(1rem) brightness(60%);
}
#cookie-banner-content {
  position: fixed;
  bottom: 0;
  width: 100%;
  min-height: 200px;
  background-color: white;
  padding: 1.5rem 3rem;
}
