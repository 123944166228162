.pagination {
  .page-item {
    .page-link {
      border-color: white;
      color: $primary;
      font-weight: 700;
      box-shadow: 0 0 15px 0 rgba(black, 0.2);
      margin-left: 0.5rem;
      transition: 0.3s ease all;
      font-size: 12px;
      background-color: white;
      &:hover {
        border-color: $color-light-blue;
        background-color: $color-light-blue;
        color: white;
      }
    }
    &.active {
      .page-link {
        border-color: $color-light-blue;
        background-color: $color-light-blue;
        color: white;
      }
    }
  }
  &-desktop{
    @include media-breakpoint-down(lg){
      display: none;
    }
  }
  &-mobile{
    @include media-breakpoint-up(lg){
      display: none;
    }
  }
}
