.topic{
    box-shadow: 0 0 12px 0 rgba(black,0.2);
    padding:18px 25px;
    background-color: white;
    border:2px solid white;
    border-radius: 20px;
    margin-bottom: 20px;
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap:2%;
    transition: 0.3s ease all;
    cursor: pointer;
    &-image{
        flex: 0 0 50px;
        max-width: 50px;
        &-wrapper{
            width:50px;
            height:50px;
            border-radius: 50%;
            background-color: grey;
            background-image: url(../img/avatar.jpg);
            background-position: center;
            background-size: cover;
        }
    }
    &-text{
        flex: 0 0 calc(100% - 50px - 2%);
        max-width: calc(100% - 50px - 2%);
        &-header{
            margin-bottom: 20px;
            .badge{
                margin-right: 15px;
            }
        }
        &-title{
            color:$primary;
            font-weight: 700;
            font-size: 20px;
            margin-bottom: 15px;
        }
        &-details{
            span{
                display: inline-block;
                margin-right: 20px;
                color:$color-orange;
                i,svg{
                    margin-right: 10px;
                    color:lightgray;
                }
            }
        }
    }
    &.active{
        border-color:$color-orange;
    }
}