.ticket-list {
    gap: 1.5%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    @include media-breakpoint-down(md) {
        gap: 0;
    }
    .ticket {
        flex: 0 0 calc(94% / 5);
        max-width: calc(94% / 5);
        margin-bottom: 25px;
        box-shadow: 0 0 13px 0 rgba(black, 0.2);
        padding: 15px;
        border-radius: 10px;
        @media screen and (min-width: 1200px) and (max-width: 1560px) {
            flex: 0 0 calc(97% / 3);
            max-width: calc(97% / 3);
        }
        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
            max-width: 100%;
        }
        &-header {
            font-weight: 700;
            font-size: 18px;
            margin-bottom: 15px;
        }
        &-body-list {
            &-item {
                margin-bottom: 10px;
                color: $primary;
                &-title {
                    color: black;
                    font-size: 14px;
                }
            }
        }
    }
    &.small {
        gap: 2.5%;
        margin-top: 10px;
        @include media-breakpoint-down(md) {
            gap: 0;
        }
        .ticket {
            flex: 0 0 calc(95% / 3);
            max-width: calc(95% / 3);
            @media screen and (min-width: 1200px) and (max-width: 1560px){
                flex: 0 0 calc(97.5% / 2);
                max-width: calc(97.5% / 2);
            }
        }
    }
}

.ticket-info-list {
    &-item {
        margin-bottom: 10px;
        &-title {
            color: $primary;
            font-size: 14px;
        }
    }
}
