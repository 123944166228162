.chat-messages {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .ps {
        height: calc(100vh - 195px - $header-height - 120px);
        padding-right: 20px;
        width: 100%;
        @media screen and (min-width: 992px) and (max-width: 1650px) {
            height: 52vh;
        }
        @media screen and (min-width: 992px) and (max-width: 1500px) {
            height: 53vh;
        }
    }
    .chat-message {
        display: flex;
        flex-wrap: wrap;
        margin-top: 8px;
        width: 100%;
        flex: 0 0 100%;
        align-items: flex-end;
        &-avatar {
            margin-right: 25px;
            @media screen and (min-width: 992px) and (max-width: 1500px) {
                .avatar {
                    width: 35px;
                    height: 35px;
                }
            }
        }
        &-wrapper {
            margin-bottom: 8px;
            max-width: 55%;
            @include media-breakpoint-down(md) {
                max-width: calc(100% - 55px);
            }
        }
        &-text {
            background-color: #e9e9e9;
            padding: 10px;
            padding-left: 20px;
            padding-right: 20px;
            @media screen and (min-width: 992px) and (max-width: 1500px) {
                padding: 10px;
            }
            pre {
                margin: 0;
                white-space: pre-wrap;
            }
            &:first-of-type {
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                padding-top: 20px;
                @media screen and (min-width: 992px) and (max-width: 1500px){
                    padding-top:10px;
                }
            }
            &:last-of-type {
                border-bottom-right-radius: 15px;
                border-bottom-left-radius: 0px;
                padding-bottom: 20px;
                @media screen and (min-width: 992px) and (max-width: 1500px){
                    padding-bottom:10px;
                }
            }
            border-radius: 0px 0px 0px 0;
            &.image {
                background-image: url(../img/ressort.png);
                background-size: cover;
                background-position: center;
                width: 330px;
                height: 200px;
                cursor: pointer;
            }
            &-date {
                font-size: 12px;
                margin-top: 4px;
            }
        }
        &-date {
            text-align: center;
            margin: 15px 0;
        }
        &.me {
            justify-content: flex-end;
            .chat-message-avatar {
                order: 2;
                margin-right: 0;
                margin-left: 25px;
            }
            .chat-message-wrapper {
                order: 1;
                .chat-message-text {
                    &:first-of-type {
                        border-top-left-radius: 15px;
                        border-top-right-radius: 15px;
                    }
                    &:last-of-type {
                        border-bottom-left-radius: 15px;
                        border-bottom-right-radius: 0px;
                    }
                    border-radius: 0px 0px 0px 0;
                    background-color: #cceaf0;
                    &-date {
                        text-align: right;
                    }
                }
            }
        }
    }
}
