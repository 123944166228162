.question-answer-list{
    .question-answer-list-item{
        ul{
            margin-bottom: 0;
        }
        margin-top: 0.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid lightgrey;
        &:last-of-type{
            border-bottom:none;
            padding-bottom: 0;
        }
        pre{
            white-space: pre-wrap;
            font-size: 1rem;
        }
    }
}