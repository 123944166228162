.wizard-custom {
  .vue-form-wizard {
    .wizard-header {
      padding: 0;
      display: none;
    }
    .wizard-navigation {
      .wizard-progress-with-circle {
        display: none;
      }
      .wizard-nav {
        margin-bottom: 35px;
        & > li {
          // flex: 0 0 100%;
          // max-width: 100%;
          // margin-bottom: 35px;
          @include media-breakpoint-down(md) {
            flex: 0 0 25%;
            max-width: 25%;
          }
          a {
            @include media-breakpoint-up(xl) {
              flex-direction: row;
            }
            .wizard-icon-circle {
              margin-right: 15px;
              transition: 0.3s ease all;
              @media screen and (min-width: 1261px) and (max-width: 1400px) {
                width: 40px;
                height: 40px;
                margin-right: 10px;
              }
              @media screen and (min-width: 992px) and (max-width: 1260px) {
                width: 35px;
                height: 35px;
              }
              @media screen and (max-width: 1200px) {
                margin-right: 0;
                margin-bottom: 10px;
              }
              i {
                font-style: normal;
              }
            }
            .stepTitle {
              color: $primary !important;
              font-weight: 700;
              @media screen and (max-width: 1700px) {
                font-size: 13px;
              }
              @media screen and (min-width: 992px) and (max-width: 1260px) {
                font-size: 12px;
              }
              @include media-breakpoint-down(sm) {
                word-break: break-all;
              }
            }
          }
          &:not(.active) {
            .wizard-icon-circle.checked {
              background-color: $success;
              border-color: $success !important;
              color: white;
            }
          }
        }
      }
      .wizard-tab-content {
        background-color: white;
        border-radius: 20px;
        box-shadow: 0 0 10px 0 rgba(black, 0.15);
        .wizard-content-header {
          padding-bottom: 30px;
          border-bottom: 1px solid #efefef;
          margin-bottom: 30px;
          @include media-breakpoint-down(md) {
            padding-bottom: 15px;
            margin-bottom: 15px;
            .btn {
              display: none;
            }
          }
        }
        .wizard-content-footer {
          margin-top: 20px;
          @include media-breakpoint-down(sm) {
            .btn {
              width: 100%;
              margin: 0 !important;
              margin-bottom: 15px !important;
            }
            .row {
              .col-lg-4 {
                order: 2;
              }
              .col-lg-8 {
                order: 1;
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                .back {
                  order: 3;
                }
              }
            }
          }
        }
        .wizard-content-header,
        .wizard-content-footer {
          .btn {
            margin-left: 20px;
            font-size: 14px;
            padding: 12px 23px;
            line-height: 1;
            background-color: #00b3c3;
            border-color: #00b3c3;
            @media screen and (min-width:992px) and (max-width:1450px){
              font-size: 12px;
              padding: 10px 20px;
            }
            &:hover {
              background-color: $primary;
              border-color: $primary;
            }
            &:not(.back) {
              i,
              svg {
                margin-left: 10px;
              }
            }
            &.back {
              background-color: #cbe9ed;
              border-color: #cbe9ed;
              color: $primary;
              i,
              svg {
                margin-right: 10px;
              }
              &:hover {
                background-color: $primary;
                border-color: $primary;
                color: white;
              }
            }
          }
        }
      }
    }
    .wizard-card-footer {
      display: none;
    }
  }
}
