.page-footer {
    padding: 1rem 3rem 2rem;
    @include media-breakpoint-down(md) {
        padding: 10px 25px;
    }
    &.no-padding {
        padding: 1rem 0 0 0;
    }
    .footer-infos {
        align-items: center;
        .version {
            font-size: 13px;
            color: $primary;
        }
        .hashtag {
            font-size: 24px;
            color: $primary;
        }
        ul {
            display: inline-block;
            margin: 0;
            padding: 0;
            list-style: none;
            margin-left: 40px;
            li {
                display: inline-block;
                margin-left: 25px;
                i,
                svg {
                    margin-right: 10px;
                    color: $color-light-blue;
                    transition: 0.3s ease all;
                }
                a {
                    color: $primary !important;
                    font-weight: 400;
                    transition: 0.3s ease all;
                    cursor: pointer;
                    &:hover {
                        color: $color-orange !important;
                    }
                }
            }
            &.icon-menu {
                @include media-breakpoint-down(md){
                    margin-left:0;
                }
                li {
                    margin-left: 5px;
                    a {
                        &:hover {
                            i,
                            svg {
                                color: $color-orange;
                            }
                        }
                    }
                }
            }
        }
    }
    hr {
        border-color: $primary;
    }
    .footer-navs {
        .nav {
            padding: 0;
            margin: 0;
            a {
                padding-left: 0;
                @include media-breakpoint-down(md){
                    padding-top:0.3rem;
                    padding-bottom: 0.3rem;
                }
                i,
                svg {
                    color: $color-light-blue;
                    margin-right: 10px;
                }
            }
        }
    }
}
