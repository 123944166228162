.event-member-page {
    .start-chat-button {
        background-color: #0000;
        border: none;
    }
}
.event-counter{
    @include media-breakpoint-down(sm){
        display:block;
        font-size: 16px !important;
    }
}