.comment {
  margin-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  &-person {
    flex: 0 0 70px;
    max-width: 70px;
    @include media-breakpoint-down(md) {
      flex: 0 0 45px;
      max-width: 45px;
    }
  }
  &-content {
    background-color: #cbe9ed;
    border-radius: 0 15px 15px 15px;
    padding: 20px;
    box-shadow: 0 0 12px 0 rgba(black, 0.2);
    flex: 0 0 calc(100% - 70px);
    color: #173f57;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    @media screen and (min-width:992px) and (max-width:1450px){
      font-size: 14px;
      line-height: 22px;
    }
    @include media-breakpoint-down(md) {
      flex: 0 0 calc(100% - 45px);
    }
    hr {
      margin-bottom: 10px;
    }
    &-sub {
      font-size: 12px;
      line-height: 1;
      color: #0b747f;
      @include media-breakpoint-down(md) {
        line-height: 1.35;
      }
      .float-right {
        @include media-breakpoint-down(md) {
          float: none !important;
          margin-top: 5px;
        }
        .comment-content-sub-button {
          display: inline-block;
          background-color: white;
          color: $color-orange;
          width: 20px;
          height: 20px;
          line-height: 20px;
          border-radius: 50%;
          text-align: center;
          margin-left: 6px;
          cursor: pointer;
          @include media-breakpoint-down(md) {
            margin-left: 0;
          }
        }
      }
      .reply-main {
        font-size: 16px;
        line-height: 1.2;
        margin-top: 20px;
        .quillWrapper {
          background-color: white;
        }
        textarea {
          padding: 7px 10px;
        }
      }
    }
  }
  &-files {
    margin: 10px 0;
    &-item {
      .thread-view-card-detail-item-content {
        font-size: 12px;
      }
      font-size: 12px;
      margin-bottom: 10px;
      cursor: pointer;

      &-name {
        margin-right: 10px;
      }
      &-size {
        margin-right: 10px;
      }
      div {
        display: inline-block;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &.sub {
    margin-left: 70px;
    @include media-breakpoint-down(md) {
      margin-left: 30px;
    }
    .comment-content {
      background-color: white;
    }
    .comment-content-sub-button {
      background-color: #efefef;
    }
  }
  &.third {
    margin-left: 140px;
  }
  &:not(.sub) {
    hr {
      border-color: white;
    }
  }
}
