.community-page{
    .topic-wrapper{
        flex: 1 1 auto;
        transition: 0.3s ease all;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        .topic-list{
            flex: 1 1 auto;
        }
        .detail-sidebar{
            flex: 0 0 auto;
            max-width: 500px;
            background-color: white;
            box-shadow: 0 0 10px 0 rgba(black,0.15);
            transition: 0.3s ease all;
            padding:20px 30px;
            height:100%;
            overflow-y: scroll;
            position: relative;
            &.open{
                width:100%;
            }
            &-header{
                margin-bottom: 25px;
                cursor: pointer;
                i,svg{
                    float:right
                }
                &:after{
                    content:'';
                    display: table;
                    clear: both;
                }
            }
            &-contact{
                display:flex;
                flex-wrap: wrap;
                flex-direction: row;
                &-person{
                    flex: 0 0 50%;
                    max-width:50%;
                    &-image{
                        display: inline-block;
                        width:35px;
                        height:35px;
                        border-radius: 50%;
                        background-size: cover;
                        background-position: center;
                        background-image: url(../img/avatar.jpg);
                    }
                    &-name{
                        display: inline-block;
                        margin-left:15px;
                        font-size: 14px;
                    }
                }
            }
            &-title{
                margin-bottom: 20px;
                color:$primary;
                font-size: 21px;
                font-weight: 600;
                margin-top:30px;
            }
            &-text{
                margin-bottom: 30px;
            }
            &-chat{
                &-title{
                    color:$primary;
                    font-size:20px;
                    margin-bottom: 15px;
                }
            }
            &-link{
                width:100%;
                padding:8px 22px;
                border:1px solid $primary;
                background-color: $primary;
                margin-bottom: 20px;
                display: block;
                position: relative;
                border-radius: 50px;
                color:white;
                transition: 0.3s ease all;
                i,svg{
                    position: absolute;
                    top:50%;
                    transform:translateY(-50%);
                    right:22px;
                }
                &:hover{
                    background-color: white;
                    color: $primary;
                }
            }
        }
    }
}