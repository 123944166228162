.chat-sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: 0;
    padding: 20px;
    background-color: white;
    transition: 0.3s ease all;
    box-shadow: 0 0 10px 0 rgba(black, 0.15);
    display: none;
    @media screen and (min-width: 992px) and (max-width: 1500px) {
        top: 0;
    }
    &.open {
        max-width: 370px;
        display: block;
    }
    &-header {
        border-bottom: 1px solid rgba(128, 128, 128, 0.45);
        padding-bottom: 15px;
        margin-bottom: 20px;
        padding-right: 35px;
        position: relative;
        font-size: 18px;
        font-weight: 500;
        span {
            color: red;
            position: absolute;
            right: 0;
            top: 0;
            font-size: 20px;
            cursor: pointer;
        }
    }
    &-body {
        &-avatar {
            margin-bottom: 35px;
            &-name {
                margin-top: 1rem;
                font-size: 18px;
            }
            .btn {
                margin-top: 15px;
            }
        }
        &-item {
            margin-bottom: 35px;
            &-headline {
                font-size: 18px;
                font-weight: 700;
            }
        }
    }
}
