@font-face {
    font-family: "Montserrat";
    font-weight: 200;
    src: url("../fonts/montserrat/Montserrat-ExtraLight.ttf") format("truetype"),
        url("../fonts/montserrat/Montserrat-ExtraLight.woff") format("woff"),
        url("../fonts/montserrat/Montserrat-ExtraLight.woff2") format("woff2");
}
@font-face {
    font-family: "Montserrat";
    font-weight: 300;
    src: url("../fonts/montserrat/Montserrat-Light.ttf") format("truetype"),
        url("../fonts/montserrat/Montserrat-Light.woff") format("woff"),
        url("../fonts/montserrat/Montserrat-Light.woff2") format("woff2");
}
@font-face {
    font-family: "Montserrat";
    font-weight: 400;
    src: url("../fonts/montserrat/Montserrat-Regular.ttf") format("truetype"),
        url("../fonts/montserrat/Montserrat-Regular.woff") format("woff"),
        url("../fonts/montserrat/Montserrat-Regular.woff2") format("woff2");
}
@font-face {
    font-family: "Montserrat";
    font-weight: 500;
    src: url("../fonts/montserrat/Montserrat-Medium.ttf") format("truetype"),
        url("../fonts/montserrat/Montserrat-Medium.woff") format("woff"),
        url("../fonts/montserrat/Montserrat-Medium.woff2") format("woff2");
}
@font-face {
    font-family: "Montserrat";
    font-weight: 600;
    src: url("../fonts/montserrat/Montserrat-SemiBold.ttf") format("truetype"),
        url("../fonts/montserrat/Montserrat-SemiBold.woff2") format("woff2");
}
@font-face {
    font-family: "Montserrat";
    font-weight: 700;
    src: url("../fonts/montserrat/Montserrat-Bold.ttf") format("truetype"),
        url("../fonts/montserrat/Montserrat-Bold.woff") format("woff"),
        url("../fonts/montserrat/Montserrat-Bold.woff2") format("woff2");
}

.text-decoration-line-through {
    text-decoration: line-through;
}

@include media-breakpoint-down(md) {
    h1 {
        font-size: 26px;
        line-height: 34px;
    }
    h2{
        font-size: 22px;
        line-height: 30px;
    }
}
