.router-wrapper {
    height: calc(100vh - $header-height);
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
        height: calc(100vh - 147px);
    }
    @include media-breakpoint-down(lg) {
        height: auto;
        padding-bottom: 55px;
    }
}

.subnav-wrapper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    max-width: 100%;
    @include media-breakpoint-up(lg) {
        height: 100%;
    }
    @media screen and (min-width: 1200px) and (max-width: 1500px) {
        flex-wrap: nowrap;
    }
    @include media-breakpoint-down(md) {
        max-width: 100%;
        flex-wrap: wrap;
    }
    .layout-container {
        flex: 1 1 auto;
        transition: 0.3s ease all;
        max-width: calc(100% - 235px);
        @media screen and (min-width: 992px) and (max-width: 1500px) {
            max-width: calc(100% - 225px);
        }
        &.big {
            max-width: calc(100% - 100px);
            @media screen and (min-width: 992px) and (max-width: 1500px) {
                max-width: calc(100% - 92px);
            }
            @include media-breakpoint-down(md) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        @include media-breakpoint-up(lg) {
            max-height: 100%;
            overflow-y: auto;
        }
        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

.page-wrapper {
    padding: 2rem 3rem;
    @media screen and (min-width: 992px) and (max-width: 1500px) {
        padding: 2rem;
    }
    @include media-breakpoint-up(lg) {
        min-height: 100%;
    }
    @include media-breakpoint-down(md) {
        padding: 1rem 1.45rem;
    }
}

.export-button {
    display: inline-block;
    margin: 0 auto;
    margin-bottom: 12px;
}

.btn {
    border-radius: 90px;
    padding: 10px 22px;
    line-height: 1;
    @media screen and (min-width: 992px) and (max-width: 1440px) {
        padding: 7px 18px;
    }
    &.btn-success {
        color: white;
    }
    &.icon-right {
        svg,
        i {
            margin-left: 10px;
            margin-right: 0;
        }
    }
}
.hover {
    &-orange {
        cursor: pointer;
        transition: 0.3s ease all;
        &:hover {
            color: $color-orange;
        }
    }
}

.small-desktop {
    &-vertical-align {
        @media screen and (max-width: 1500px) {
            display: flex;
            align-items: center;
        }
    }
}

.b-toaster-bottom-center {
    font-size: 16px;
    .toast {
        font-size: 16px;
    }
}

@media screen and (max-width: 1440px) {
    .mobile-order {
        &-1 {
            order: 1;
        }
        &-2 {
            order: 2;
        }
    }
    .dropdown-menu{
        font-size: 13px;
    }
}

.administration{
    @include media-breakpoint-down(sm){
        display: none !important;
    }
}