.form-wrapper {
  color: $primary;
  &-header {
    margin-bottom: 20px;
    &-title {
      font-size: 28px;
      font-weight: 700;
      @include media-breakpoint-down(md) {
        font-size: 22px;
      }
    }
  }
  h3 {
    margin-bottom: 15px;
    font-weight: 400;
    @include media-breakpoint-down(md) {
      font-size: 19px;
    }
  }
  &-footer {
    margin-top: 20px;
    text-align: right;
    .btn {
      margin-bottom: 0;
    }
    .btn-success {
      color: white;
      i,
      svg {
        margin-left: 10px;
      }
    }
  }
  input:not(.vs__search) {
    border-radius: 0;
    margin-bottom: 20px;
    color: $primary;
    padding: 13px 17px;
    line-height: 1.35;
    border-color: #dadada;
  }
  hr {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.v-select {
  .vs__dropdown-toggle {
    min-height: 38px;
  }
}

ul.form-error-list {
  margin: 0;
  padding: 0;
}

input,
select,
.form-control {
  border-radius: 0;
}
.form-group {
  position: relative;
  // legend.col-form-label{
  //   color:#ced4da;
  // }
  .col-form-label {
    @media screen and (min-width: 992px) and (max-width: 1450px) {
      font-size: 12px;
    }
  }
}
.custom-file {
  label {
    border-radius: 0;
    &:after {
      border-radius: 0;
    }
  }
}
.form-headline {
  font-size: 18px;
  font-weight: 400;
  color: $primary;
  margin-bottom: 15px;
}
.form-group .b-form-datepicker {
  .btn {
    margin-top: 0;
  }
}
.b-form-btn-label-control.form-control {
  align-items: center;
}
.form-control {
  &.time {
    height: 42px;
  }
}
.vs__dropdown-toggle {
  border-radius: 0 !important;
  .vs__search,
  .vs__search:focus {
    border-radius: 0 !important;
  }
}
@include media-breakpoint-down(sm) {
  .date-time-input {
    flex-wrap: wrap;
    .form-control.time {
      flex: 0 0 100%;
    }
    .b-form-datepicker {
      flex: 0 0 100%;
    }
  }
}
.action-form,
.action-form-section {
  .form-control:disabled,
  .form-control[readonly],
  .model-input[readonly] {
    pointer-events: none;
  }
}
.model-input[readonly] {
  .vs__dropdown-toggle {
    background-color: #e9ecef;
    opacity: 1;
  }
}
.b-form-btn-label-control {
  & > .btn {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.custom-select {
  border-radius: 0;
}
@media screen and (min-width: 992px) and (max-width: 1450px) {
  .form-control,
  .vs__search,
  .vs__search:focus {
    font-size: 14px;
  }
  .b-form-btn-label-control.b-form-datepicker > .form-control {
    font-size: 12px;
  }
  .date-time-input {
    flex-wrap: wrap;
    flex-direction: row;
    .b-form-datepicker {
      flex: 0 0 100%;
    }
    input.time {
      flex: 0 0 100%;
    }
  }
  .custom-select {
    font-size: 14px;
  }
  .input-group-text {
    font-size: 14px;
  }
}

@include media-breakpoint-up(lg) {
  .small-address-input {
    .address-plz {
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
    .address-city {
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
    .address-country {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.small-address-input {
  .address-line-1,
  .address-line-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
