.reply-main {
  padding: 12px 0;
  textarea {
    width: 100%;
    height: 175px;
  }
  .btn{
    margin-top:15px;
  }
}
