@import "vars";

// Import Bootstrap and BootstrapVue CSS files (order is important)
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-vue/dist/bootstrap-vue.css";

@import "util";
@import "fonts";

@import "layout";
@import "components";
@import "views";

body {
    background-color: #f1f9fb;
}

.phone-with-prefix fieldset > legend + div {
    display: flex;
    gap: 8px;
}

.phone-with-prefix fieldset > legend + div > span:first-child {
    flex: 0 120px;
}
.phone-with-prefix fieldset > legend + div > span:last-child {
    flex: 1;
}
.phone-with-prefix fieldset fieldset legend {
    display: none;
}

.custom-checkbox .custom-control-label::before,
.custom-checkbox .custom-control-label::after {
  top: 0 !important;
}