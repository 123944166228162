.filemanager {
    &-filter {
        h3 {
            color: $primary;
            font-weight: 600;
            @media screen and (max-width: 1500px) {
                font-size: 18px;
            }
            @include media-breakpoint-down(md) {
                font-size: 18px;
            }
        }
        .accordion {
            .card {
                border-bottom: 1px solid #dee2e6;
                &-header {
                    margin: 0;
                    .btn {
                        padding: 10px 0;
                        background-color: transparent;
                        color: $primary;
                        font-size: 18px;
                        font-weight: 400;
                        border: 0;
                        @media screen and (max-width: 1500px) {
                            font-size: 16px;
                        }
                        i,
                        svg {
                            right: 0;
                            color: $color-orange;
                        }
                    }
                }
                .collapse {
                    border: 0;
                    .card-body {
                        padding: 15px 0;
                        .form-group {
                            margin: 0;
                            .custom-control {
                                .custom-control-label {
                                    line-height: 24px;
                                }
                            }
                        }
                    }
                }
                &:last-child {
                    border: 0;
                }
            }
        }
        @media screen and (max-width: 1500px) {
            .badge {
                font-size: 10px;
            }
        }
    }
}
.search-field-files {
    margin-bottom: 25px;
    position: relative;
    input {
        width: 100%;
        max-width: unset;
        background-color: white;
        border: 1px solid white;
        box-shadow: $shadow-default;
        font-size: 16px;
        color: $primary;
        font-weight: 500;
        padding: 12px 25px;
        border-radius: 50px;
        outline: none;
        transition: 0.3s ease all;
        @media screen and (max-width: 1500px) {
            min-height: 50px;
            font-size: 14px;
        }
        @include media-breakpoint-down(md) {
            font-size: 14px;
        }
        &:focus {
            border-color: rgba($color-light-blue, 0.75);
        }
    }
    span {
        background-color: $color-light-blue;
        color: white;
        width: 43px;
        height: 43px;
        border-radius: 50%;
        position: absolute;
        right: 3px;
        top: 3px;
        text-align: center;
        line-height: 44px;
        cursor: pointer;
        border: 1px solid $color-light-blue;
        transition: 0.3s ease all;
        &:hover {
            background-color: white;
            color: $color-light-blue;
        }
    }
    ::placeholder {
        text-transform: uppercase;
    }
}
