.company-member-list {
    &-item {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-bottom: 15px;
        border: 1px solid #d5d5d5;
        background-color: white;
        padding: 15px 20px;
        color: $primary;
        &-header {
            background-color: $primary;
            color: white;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 18px;
            padding: 5px 15px;
            @media screen and (min-width:992px) and (max-width:1450px){
                font-size: 14px;
            }
        }
        &-content {
            flex: 0 0 calc(100% - 45px);
            max-width: calc(100% - 45px);
            @media screen and (min-width:992px) and (max-width:1450px){
                font-size: 14px;
            }
            &-name {
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 4px;
                @media screen and (min-width:992px) and (max-width:1450px){
                    font-size: 14px;
                }
            }
            .badge {
                background-color: #f8f8f8;
                font-size: 12px;
            }
            &-data {
                margin-top: 10px;
                a {
                    display: block;
                    margin-bottom: 4px;
                    i,
                    svg {
                        color: $color-orange;
                        margin-right: 10px;
                    }
                }
                @include media-breakpoint-down(md){
                    .btn{
                        width:100%;
                        font-size: 13px;
                        text-align: left;
                    }
                }
            }
        }
        &-img {
            flex: 0 0 45px;
            max-width: 45px;
            text-align: right;
        }
        .company-member-list-item-content-data-container{
            font-size: 14px;
        }
    }
    &.wide {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 1.5%;
        .company-member-list-item {
            flex: 0 0 calc(97% / 3);
            max-width: calc(97% / 3);
            margin-bottom: 20px;

            @media screen and (min-width: 992px) and (max-width: 1450px) {
                flex: 0 0 calc(98.5% / 2);
                max-width: calc(98.5% / 2);
            }
            @include media-breakpoint-down(md){
                flex: 0 0 100%;
                max-width:100%;
            }
        }
    }
}
