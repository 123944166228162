.ressort-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1.5%;
    color: $primary;
    &-header {
        @media screen and (max-width: 1500px) {
            margin-bottom: 5px;
        }
    }
    .ressort-item {
        flex: 0 0 calc(97%/ 3);
        max-width: calc(97%/ 3);
        margin-bottom: 35px;
        @media screen and (min-width: 992px) and (max-width: 1450px) {
            flex: 0 0 calc(95.5% / 2);
            max-width: calc(95.5% / 2);
        }
        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
            max-width: 100%;
        }
        &-title {
            border-bottom: 1px solid rgba(grey, 0.3);
            margin-bottom: 20px;
            span {
                width: 50px;
                height: 50px;
                display: inline-block;
                border-radius: 50%;
                text-align: center;
                line-height: 48px;
                color: white;
                background-color: $color-orange;
                font-size: 22px;
                @media screen and (min-width:992px) and (max-width:1500px){
                    width:40px;
                    height: 40px;
                    line-height: 38px;
                    font-size: 18px;
                }
                @include media-breakpoint-down(sm){
                    width:35px;
                    height:35px;
                    line-height: 33px;
                    font-size: 16px;
                }
            }
            div {
                font-weight: 700;
                margin: 10px 0 15px;
                font-size: 18px;
                @include media-breakpoint-up(lg) {
                    min-height: 55px;
                }
                @media screen and (min-width:992px) and (max-width:1450px){
                    min-height: auto;
                }
                @include media-breakpoint-down(sm){
                    font-size: 16px;
                    min-height: unset;
                    margin-bottom: 10px;
                }
            }
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }
    }
}
.letter-list {
    @include media-breakpoint-down(md) {
        display: none;
    }
    button {
        background-color: transparent;
        border: 0;
        text-transform: uppercase;
        border-radius: 0;
        color: $primary;
        font-weight: 700;
        transition: 0.3s ease all;
        // line-height: 1;
        width: 25px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        padding: 0;
        margin-right: 3px;
        &:last-of-type {
            margin-right: 0;
        }
        &:hover,
        &.active {
            background-color: $color-orange;
            color: white;
        }
    }
    &-mobile {
        @media screen and (min-width: 1500px) {
            display: none !important;
        }
    }
    &-desktop {
        @media screen and (max-width: 1500px) {
            display: none !important;
        }
    }
}
.ressort-list-header {
    align-items: center;
}
