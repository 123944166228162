.navbar {
  background-color: #f7f7f7;
  padding-top: 12px;
  padding-bottom: 12px;
  @include media-breakpoint-down(lg) {
    position: relative;
    z-index: 20;
  }
  .navbar-toggler {
    border: 0;
    color: white;
  }
  .navbar-brand {
    margin-right: 3rem;
    @media screen and (min-width: 1200px) and (max-width: 1560px) {
      margin-right: 1.5rem;
    }
    img {
      max-height: 70px;
      @media screen and (min-width: 1200px) and (max-width: 1400px) {
        max-height: 65px;
      }
      @include media-breakpoint-down(lg) {
        max-height: 55px;
      }
    }
  }
  & > .nav-actions {
    @include media-breakpoint-up(xl) {
      display: none;
    }
    @include media-breakpoint-down(lg) {
      flex-direction: row;
      span:not(.icon-bullet) {
        display: none;
      }
      li.nav-item {
        .nav-link {
          color: $primary;
          margin-right: 15px;
          font-size: 20px;
        }
      }
      .dropdown-menu {
        position: absolute;
      }
      .form-inline {
        .icon-bullet {
          color: $primary;
          margin-right: 15px;
          font-size: 20px;
        }
        .mobile-search {
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          background-color: white;
          padding: 10px;
          .form-control {
            width: 100%;
            height: 36px;
            border-radius: 100px;
            padding: 0.5rem 1rem;
          }
        }
      }
    }
  }
  .navbar-collapse {
    li.form-inline {
      background-color: white;
      padding: 7px;
      border-radius: 50px;
      box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.07);
      position: relative;
      margin-left: 30px;
      @media screen and (min-width: 1600px) and (max-width: 1749px) {
        width: 15%;
      }
      @media screen and (min-width: 1750px) {
        width: 25%;
      }
      @media screen and (min-width: 1200px) and (max-width: 1560px) {
        margin-right: 15px;
        margin-left: 15px;
        padding: 4px;
      }
      @include media-breakpoint-down(lg) {
        margin-left: 0;
      }
      .form-inline {
        width: 100%;
        input.form-control {
          width: 100%;
          height: 36px;
          border-radius: 100px;
          padding: 0.5rem 1rem;
          border: 0;
          @media screen and (min-width: 1200px) and (max-width: 1560px) {
            height: 32px;
          }
        }
        .btn {
          height: 36px;
          width: 36px;
          line-height: 36px;
          text-align: center;
          padding: 0;
          border-radius: 50%;
          background-color: $color-light-blue;
          color: white;
          border-color: $color-light-blue;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 7px;
          @media screen and (min-width: 1200px) and (max-width: 1560px) {
            height: 32px;
            width: 32px;
            line-height: 32px;
            right: 4px;
          }
        }
      }
    }
    .navbar-nav.nav-main {
      li.nav-item {
        margin-right: 5px;
        .nav-link {
          color: $primary;
          padding: 10px 20px;
          border-radius: 90px;
          border: 1px solid transparent;
          line-height: 1;
          transition: 0.3s ease all;
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 600;
          @media screen and (min-width: 1551px) and (max-width: 1770px) {
            padding: 10px 15px;
          }
          @media screen and (min-width: 1451px) and (max-width: 1635px) {
            font-size: 16px;
          }
          @media screen and (min-width: 1200px) and (max-width: 1450px) {
            font-size: 12px;
          }
          @media screen and (min-width: 1200px) and (max-width: 1350px) {
            font-size: 12px;
          }
          @media screen and (min-width: 1200px) and (max-width: 1550px) {
            padding: 9px 12px;
          }
          @include media-breakpoint-down(md) {
            color: $primary;
            font-size: 16px;
            padding: 10px 18px;
            padding-left: 2px;
            border-width: 0 0 1px 0;
            border-radius: 0;
          }
          &:hover,
          &.router-link-active {
            border-color: $primary;
            @include media-breakpoint-down(md) {
              border-color: $primary;
            }
          }
        }
        &.show {
          & > a {
            border-color: $primary;
          }
        }
        .dropdown-menu {
          @include media-breakpoint-down(md) {
            background-color: transparent;
            border: 0;
            border-radius: 0;
            .dropdown-item {
              padding: 0.25rem 0.5rem;
            }
          }
          .dropdown-item{
            @media screen and (max-width:1500px){
              font-size: 14px;
              padding:0.25rem 1rem;
            }
          }
        }
      }
    }
    .nav-actions {
      @include media-breakpoint-up(xl) {
        padding-left: 20px !important;
      }
      @include media-breakpoint-down(lg) {
        display: none;
      }
      & > .nav-item {
        & > a {
          color: $primary;
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;
          text-align: center;
          padding: 0;
          margin-right: 14px;
          & > span.icon-bullet {
            display: block;
            width: 44px;
            height: 44px;
            border-radius: 50%;
            text-align: center;
            line-height: 44px;
            background-color: transparent;
            color: $color-orange;
            padding: 0;
            border: 1px solid $color-light-blue;
            transition: 0.3s ease all;
            margin: 0 auto;
            margin-bottom: 5px;
            font-size: 18px;
            @media screen and (min-width: 1551px) and (max-width: 1770px) {
              width: 41px;
              height: 41px;
              line-height: 41px;
              font-size: 16px;
            }
            @media screen and (min-width: 1200px) and (max-width: 1550px) {
              width: 38px;
              height: 38px;
              line-height: 38px;
              font-size: 16px;
            }
            @include media-breakpoint-down(lg) {
              background-color: white;
            }
            &:hover {
              background-color: $color-light-blue;
              color: white;
            }
          }
          @media screen and (min-width: 1200px) and (max-width: 1500px) {
            span.nav-item-subtitle {
              display: none;
            }
          }
        }
      }
      & > .b-nav-dropdown {
        padding: 0;
        & > a {
          color: $primary;
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;
          text-align: center;
          @media screen and (min-width: 1200px) and (max-width: 1500px) {
            margin-right: 0;
          }
          & > span.icon-bullet {
            display: block;
            width: 44px;
            height: 44px;
            border-radius: 50%;
            text-align: center;
            line-height: 44px;
            background-color: transparent;
            color: $color-orange;
            padding: 0;
            border: 1px solid $color-light-blue;
            transition: 0.3s ease all;
            margin: 0 auto;
            margin-bottom: 5px;
            font-size: 18px;
            @media screen and (min-width: 1551px) and (max-width: 1770px) {
              width: 41px;
              height: 41px;
              line-height: 41px;
              font-size: 16px;
            }
            @media screen and (min-width: 1200px) and (max-width: 1550px) {
              width: 38px;
              height: 38px;
              line-height: 38px;
              font-size: 16px;
            }
            @include media-breakpoint-down(lg) {
              background-color: white;
            }
            &:hover {
              background-color: $color-light-blue;
              color: white;
            }
          }
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
