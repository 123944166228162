.chat-overview {
    @import "./list";
    @import "./group-members";
    @import "./media-list";
    @import "./messages";
    @import "./show";
    @import "./sidebar";
    .subnav-sidebar-header {
        padding: 28px 20px;
        width: 420px;
        max-width: 420px;
        @media screen and (min-width:1200px) and (max-width:1560px){
            width: 350px;
            max-width: 350px;
        }
        @include media-breakpoint-down(md) {
            width: 100%;
            max-width: 100%;
        }
        &:after {
            content: "";
            clear: both;
            display: table;
        }
        .btn {
            float: right;
            display: inline-block;
            width: auto;
            font-size: 21px;
            position: relative;
            span {
                background-color: $color-orange;
                color: white;
                font-weight: 700;
                font-size: 15px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                text-align: center;
                line-height: 20px;
                display: inline-block;
                position: absolute;
                top: -10px;
                left: -15px;
            }
        }
    }
    @include media-breakpoint-down(md) {
        .subnav-sidebar-body {
            display: block;
        }
    }
    .layout-container {
        position: relative;
        @include media-breakpoint-down(md) {
            position: absolute;
            bottom: 0;
            right: 0;
            height: 0;
            background-color: #f1f9fb;
            z-index: 20;
            overflow:hidden;
            transition: 0.3s ease all;
            height: calc(100vh - 118px);
            width:0;
            &.open{
                width:100%;
            }
        }
        .no-chats {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $primary;
            font-size: 18px;
            font-style: italic;
        }
    }
    .chat-close{
        @include media-breakpoint-up(lg){
            display: none;
        }
    }
}
