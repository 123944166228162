.event-check-overview {
    .col-lg-6 {
        & > div {
            margin-bottom: 10px;
            span {
                color: $primary;
                font-weight: 700;
            }
        }
    }
    &-headline {
        border-bottom: 1px solid lightgrey;
        font-size: 22px;
        padding-bottom: 10px;
        color: $primary;
        margin-top: 20px;
        margin-bottom: 15px;
    }
    &-headline-2 {
        border-bottom: 1px solid rgba(211, 211, 211, 0.521);
        font-size: 16px;
        padding-bottom: 8px;
        color: $primary;
        margin-top: 13px;
        margin-bottom: 8px;
    }
}
.calendar-view {
    .fc-daygrid-event,
    .fc-timegrid-event {
        overflow: hidden;
    }
    .fc-timegrid-event {
        padding-left: 10px;
        padding-right: 10px;
        .event-sub-row{
            display: none;
        }
    }
    .online-event {
        background: rgba(239, 88, 68, 0.25) !important;
        border: 0 !important;
        color: black !important;
        margin-bottom: 2px !important;
        .fc-event-main {
            color: black !important;
        }
    }
    .hybrid-event {
        background: rgba(0, 73, 90, 0.25) !important;
        border: 0 !important;
        color: black !important;
        margin-bottom: 2px !important;
        .fc-event-main {
            color: black !important;
        }
    }
    .other-event {
        background: rgba(0, 179, 195, 0.25) !important;
        border: 0 !important;
        color: black !important;
        margin-bottom: 2px !important;
        .fc-event-main {
            color: black !important;
        }
    }
}
.export {
    padding: 0;
}