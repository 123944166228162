.avatar-group{
    display: flex;
    flex-direction: row;
    align-items: center;
    @include media-breakpoint-down(lg){
        margin-bottom: 20px;
    }
    .avatar{
        margin-right: 20px;
    }
    .avatar-content{
        &-name{
            font-weight: 700;
            font-size: 20px;
            margin-bottom: 15px;
            @include media-breakpoint-down(lg){
                font-size: 18px;
            }
        }
        &-position{
            font-size: 18px;
            @include media-breakpoint-down(lg){
                font-size: 16px;
            }
        }
    }
}