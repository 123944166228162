.my-project-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 25px;
    &-item {
        flex: 0 0 calc((100% - 100px) / 5);
        box-shadow: $shadow-default;
        padding:20px;
        border-radius: 10px;
        &-title{
            font-weight: 700;
            font-size: 18px;
        }
    }
}
