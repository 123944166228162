.matching-overview-projects {
    gap: 1.5%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    .project-item {
        flex: 0 0 32.33%;
        max-width: 32.33%;
        box-shadow: $shadow-default;
        padding: 15px;
        border-radius: 10px;
        margin-bottom: 15px;
    }
}
