.subnav-sidebar {
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(black, 0.15);
  flex: 0 0 auto;
  transition: 0.3s ease all;
  position: relative;
  z-index: 10;
  max-height: 100%;
  overflow-y: auto;
  @include media-breakpoint-down(md) {
    flex: 0 0 100%;
    overflow: unset;
    box-shadow: none;
  }
  &-header {
    padding: 15px 20px;
    border-bottom: 1px solid #e3e3e3;
    @include media-breakpoint-down(md) {
      padding: 10px 10px;
    }
    button {
      width: 100%;
      background-color: transparent;
      border: 0;
      box-shadow: none;
      text-align: center;
      color: $color-orange;
      padding: 0;
      @include media-breakpoint-down(lg) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
      div {
        color: $primary;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px;
        @media screen and (min-width: 992px) and (max-width: 1450px) {
          font-size: 14px;
        }
        @include media-breakpoint-down(md) {
          font-size: 14px;
          display: inline-block;
          margin-left: 10px;
        }
      }
    }
  }
  &-nav {
    &-item {
      padding: 15px 20px;
      display: block;
      transition: 0.3s ease all;
      font-size: 14px;
      text-transform: uppercase;
      max-width: 350px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media screen and (min-width: 992px) and (max-width: 1450px) {
        padding: 10px 15px;
        font-size: 12px;
      }
      @include media-breakpoint-down(md) {
        padding: 10px 10px;
        font-size: 14px;
        text-align: center;
        max-width: unset;
      }
      span {
        display: inline-block;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        line-height: 43px;
        text-align: center;
        background-color: transparent;
        border: 1px solid $color-light-blue;
        color: $color-orange;
        font-size: 17px;
        transition: 0.3s ease all;
        @media screen and (min-width: 992px) and (max-width: 1450px) {
          width: 35px;
          height: 35px;
          line-height: 33px;
          font-size: 14px;
        }
        @include media-breakpoint-down(md) {
          width: 35px;
          height: 35px;
          line-height: 33px;
          font-size: 16px;
        }
      }
      &:hover,
      &.active {
        background-color: $color-light-blue;
        // font-weight: 600;
        color: white;
        span {
          background-color: white;
          color: $primary;
        }
      }
      &.active {
        font-weight: 600;
      }
      &.is-admin {
        background-color: $primary;
        color: white;
        @include media-breakpoint-down(sm) {
          display: none;
        }
        span {
          color: white;
        }
        &:hover,
        &.active {
          span {
            color: $color-orange;
          }
        }
      }
    }
  }
  &-body {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  &-mobile {
    @include media-breakpoint-up(lg) {
      display: none;
    }
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    box-shadow: 0 5px 10px 0 rgba(black, 0.15);
  }
  &.open {
    // [data-simplebar]{
    //   min-width: 250px;
    // }
    .subnav-sidebar-nav-item {
      text-align: left;
      span {
        margin-right: 15px;
        @media screen and (min-width: 992px) and (max-width: 1450px) {
          margin-right: 10px;
        }
      }
    }
  }
  &.admin-nav {
    background-color: #173f57;
    .subnav-sidebar-header button div {
      color: white;
    }
    .subnav-sidebar-nav-item {
      color: white;
      padding: 10px 20px;
      @include media-breakpoint-down(md) {
        color: $primary;
        &.active {
          color: white;
        }
      }
      span {
        background-color: white;
        color: $color-orange;
        border: 0;
      }
      &:hover {
        color: white;
        span {
          color: $primary;
        }
      }
    }
  }
  &.projectroom-nav{
    background-color: #008A85;
    .subnav-sidebar-header button div {
      color: white;
    }
    .subnav-sidebar-nav-item {
      color: white;
      @include media-breakpoint-down(md) {
        color: $primary;
        &.active {
          color: white;
        }
      }
      span {
        background-color: transparent;
        color: white;
      }
      &:hover, &.active {
        color: $primary;
        background-color: #CCE8F0;
        span {
          color: $primary;
          background-color: white;
          border-color: $color-light-blue;
        }
      }
    }
  }
  &:not(.open){
    @include media-breakpoint-only(lg){
      .subnav-sidebar-nav-item{
        text-align: center;
      }
    }
  }
}

.subnav-member {
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(black, 0.15);
  flex: 0 0 auto;
  transition: 0.3s ease all;
  max-width: 140px;
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
  @media screen and (min-width: 992px) and (max-width: 1350px) {
    max-width: 120px;
  }
  @include media-breakpoint-down(md) {
    max-width: unset;
    flex: 0 0 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: unset;
    z-index: 100;
    .ps {
      position: static;
    }
  }
  &-header {
    padding: 15px 20px;
    border-bottom: 1px solid #e3e3e3;
    width: 100%;
    background-color: transparent;
    box-shadow: none;
    text-align: center;
    color: $color-orange;
    div {
      color: $primary;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 10px;
      @media screen and (min-width: 992px) and (max-width: 1350px) {
        word-break: break-all;
        font-size: 8px;
      }
      @include media-breakpoint-down(md) {
        display: inline-block;
        margin-left: 10px;
      }
    }
  }
  &-item {
    display: block;
    transition: 0.3s ease all;
    text-align: center;
    border-bottom: 1px solid #e3e3e3;
    &-header {
      font-size: 10px;
      font-weight: 700;
      color: $primary;
      margin: 15px 25px 5px;
      @media screen and (min-width: 992px) and (max-width: 1350px) {
        margin: 15px 20px 5px;
        font-size: 8px;
      }
      @include media-breakpoint-down(md) {
        margin: 15px 10px 10px;
        div {
          display: inline-block;
        }
      }
    }
    &-member {
      position: relative;
      padding: 10px 25px;
      @media screen and (min-width: 992px) and (max-width: 1350px) {
        padding: 10px 20px;
      }
      @include media-breakpoint-down(md) {
        padding: 10px 10px;
      }
      &-body {
        font-size: 10px;
        font-weight: 600;
        color: $primary;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
      &-hover {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #cbe9ed;
        font-size: 10px;
        font-weight: 700;
        color: $primary;
        padding: 10px 15px;
        transition: 0.3s ease all;
        opacity: 0;
        visibility: hidden;
        @media screen and (min-width: 992px) and (max-width: 1350px) {
          padding: 10px;
        }
        @include media-breakpoint-down(md) {
          padding: 10px;
        }
        div {
          margin-bottom: 8px;
        }
        button,
        a,
        .subnav-member-item-member-hover-action {
          display: block;
          border: none;
          background-color: #0000;
          font-weight: 700;
          color: $primary;
          text-align: left;
          margin-bottom: 10px;
          @include media-breakpoint-down(md) {
            width: 49%;
            margin-right: 1%;
            float: right;
            margin-bottom: 0;
            margin-top: 10px;
          }
          span {
            display: inline-block;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            color: white;
            line-height: 26px;
            text-align: center;
            background-color: $color-light-blue;
            margin-right: 10px;
          }
        }
        .start-chat-button {
          i,
          svg {
            margin: 0 !important;
          }
        }
      }
      &:hover {
        .subnav-member-item-member-hover {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    .subnav-member-body {
      visibility: hidden;
      opacity: 0;
      transition: 0.3s ease all;
      display: none;
      position: absolute;
      bottom: 100%;
      left: 0;
      background-color: white;
      right: 0;
      box-shadow: 0 -5px 10px 0 rgba(black, 0.15);
      z-index: 99;
      border-bottom: 1px solid rgba(grey, 0.15);
      &.open {
        visibility: visible;
        opacity: 1;
        display: block;
        max-height: 350px;
        overflow-y: auto;
      }
    }
  }
}
