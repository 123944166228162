.navbar-toggler {
  #nav-icon {
    width: 25px;
    height: 25px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  #nav-icon span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: $primary;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  #nav-icon span:nth-child(1) {
    top: 3px;
  }

  #nav-icon span:nth-child(2),
  #nav-icon span:nth-child(3) {
    top: 11px;
  }

  #nav-icon span:nth-child(4) {
    top: 19px;
  }

  &.not-collapsed {
    #nav-icon span:nth-child(1) {
      top: 18px;
      width: 0%;
      left: 50%;
    }

    #nav-icon span:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    #nav-icon span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    #nav-icon span:nth-child(4) {
      top: 18px;
      width: 0%;
      left: 50%;
    }
  }
}

.subnav-sidebar-header {
  #nav-icon {
    width: 20px;
    height: 20px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    // -webkit-transition: 0.5s ease-in-out;
    // -moz-transition: 0.5s ease-in-out;
    // -o-transition: 0.5s ease-in-out;
    // transition: 0.5s ease-in-out;
    cursor: pointer;
    margin:0 auto;
    margin-bottom: 3px;
    @include media-breakpoint-down(lg){
      margin: 0;
    }
    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: $color-orange;
      border-radius: 0;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      // -webkit-transition: 0.25s ease-in-out;
      // -moz-transition: 0.25s ease-in-out;
      // -o-transition: 0.25s ease-in-out;
      // transition: 0.25s ease-in-out;
      &:nth-child(1) {
        top: 1px;
      }
      &:nth-child(2),
      &:nth-child(3) {
        top: 8px;
      }
      &:nth-child(4) {
        top: 15px;
      }
    }
    &.open {
      span {
        &:nth-child(1) {
          top: 18px;
          width: 0%;
          left: 50%;
        }
        &:nth-child(2) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        &:nth-child(3) {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
        &:nth-child(4) {
          top: 18px;
          width: 0%;
          left: 50%;
        }
      }
    }
  }
}
