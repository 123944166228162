.matching-filter {
    .card-header {
        color: $primary;
        &-title {
            text-transform: uppercase;
        }
        span {
            font-size: 14px;
            font-weight: 500;
        }
    }
    .accordion {
        .collapse {
            padding: 20px;
            .form-group {
                margin: 0;
            }
        }
    }
}
