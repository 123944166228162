.gremium-visibility-traffic-light {
    border-radius: 50%;
    background-color: $color-green;
    &.red{
        background-color: $color-red;
    }
    &.yellow{
        background-color: $color-yellow;
    }
    display: inline-block;
    margin-right:10px;
    // transform-origin: center;
    // transform: translateY(25%);
}
