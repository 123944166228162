.news-list {
  .news-item {
    padding: 25px 40px;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 0 20px 0 rgba(black, 0.15);
    color: $primary;
    margin-bottom: 25px;
    @include media-breakpoint-down(md) {
      padding: 20px;
    }
    .row {
      align-items: center;
    }
    &-title {
      color: $primary;
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 20px;
      display: block;
      @media screen and (min-width: 992px) and (max-width: 1450px) {
        font-size: 22px;
      }
      @include media-breakpoint-down(md) {
        font-size: 18px;
      }
    }
    &-excerpt {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
      max-height: 50px;
      @media screen and (min-width: 992px) and (max-width: 1450px) {
        font-size: 14px;
      }
      @include media-breakpoint-down(md) {
        font-size: 14px;
      }
      p {
        margin: 0;
      }
      table {
        width: 100% !important;
        max-height: 80px;
        height: 80px;
        overflow: hidden;
        td {
          width: 100% !important;
        }
      }
      a {
        text-decoration: underline !important;
      }
    }
    &-img-wrapper {
      text-align: center;
    }
    &-footer {
      margin-top: 30px;
      border-top: 1px solid grey;
      padding-top: 20px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
      @media screen and (min-width: 992px) and (max-width: 1450px) {
        font-size: 12px;
      }
      @include media-breakpoint-down(md) {
        margin-top: 15px;
        padding-top: 15px;
      }
      &-creator {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        margin-right: 35px;
        @include media-breakpoint-down(md) {
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 15px;
          margin-right: 0;
        }
        .avatar {
          margin-right: 10px;
        }
      }
      &-date {
        font-weight: 600;
        margin-right: 35px;
        @include media-breakpoint-down(md) {
          flex: 0 0 100%;
          max-width: 100%;
          margin-right: 0;
        }
      }
      &-actions {
        font-weight: 600;
        span {
          margin-right: 35px;
        }
        @media screen and (min-width: 1200px) and (max-width: 1560px) {
          flex: 0 0 100%;
          max-width: 100%;
          margin-top: 10px;
        }
        @include media-breakpoint-down(md) {
          flex: 0 0 100%;
          max-width: 100%;
          span {
            margin: 0;
            width: 100%;
            display: block;
          }
        }
      }
      i,
      svg {
        color: $color-orange;
        margin-right: 10px;
        font-size: 18px;
      }
    }
    img {
      max-width: 100%;
      height: auto;
      max-height: 300px;
      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
      }
    }
    &:nth-child(even) {
      .col-lg-3 {
        order: 1;
      }
      .col-lg-9 {
        order: 2;
      }
    }
    @include media-breakpoint-down(md) {
      .col-lg-3 {
        order: 1;
      }
      .col-lg-9 {
        order: 2;
      }
    }
  }
}

.news-preview {
  border-bottom: 1px solid #e3e3e3;
  padding: 15px;
  &-image {
    margin-bottom: 10px;
  }
  &-title {
    font-weight: 600;
  }
  &-content {
    position: relative;
    overflow: hidden;
    &:after {
      content: "";
      display: block;
      width: 100%;
      position: absolute;
      height: 35px;
      background: rgb(255, 255, 255);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
  &-excerpt {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    max-height: 100px;
    font-size: 12px;
  }
}
