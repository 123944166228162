.header-actions {
    background-color: white;
    text-align: right;
    padding: 3px 20px;
    font-size: 13px;
    @media screen and (min-width: 992px) and (max-width: 1450px) {
        font-size: 11px;
    }
    @include media-breakpoint-down(md) {
        display: none;
    }
    ul {
        display: inline-block;
        margin: 0;
        padding: 0;
        list-style: none;
        margin-left: 40px;
        li {
            display: inline-block;
            margin-left: 25px;
            i,
            svg {
                margin-right: 10px;
                color: $color-light-blue;
                transition: 0.3s ease all;
            }
            a {
                color: $primary !important;
                font-weight: 400;
                transition: 0.3s ease all;
                cursor: pointer;
                &:hover {
                    color: $color-orange !important;
                }
            }
        }
        &.icon-menu {
            li {
                margin-left: 5px;
                a {
                    &:hover {
                        i,
                        svg {
                            color: $color-orange;
                        }
                    }
                }
            }
        }
    }
    &.golden-master {
        background-color: red;
    }
}
