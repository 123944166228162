.accordion {
    .card {
        border-radius: 0;
        box-shadow: none;
        .card-header {
            background-color: transparent;
            padding: 0;
            .btn {
                border-radius: 0;
                text-align: left;
                color: $primary;
                background-color: #efefef;
                border-color: #efefef;
                font-weight: 700;
                position: relative;
                font-size: 18px;
                padding: 15px 25px;
                @media screen and (min-width: 992px) and (max-width: 1450px) {
                    font-size: 14px;
                    padding: 15px;
                }
                i,
                svg {
                    position: absolute;
                    color: $color-light-blue;
                    top: 50%;
                    right: 25px;
                    transform: translateY(-50%);
                    transition: 0.3s ease all;
                }
                &.not-collapsed {
                    i,
                    svg {
                        transform: translateY(-50%) rotate(180deg);
                    }
                }
            }
        }
        .collapse {
            .card-body {
                padding: 25px;
                font-size: 14px;
                color: $primary;
                @media screen and (min-width: 992px) and (max-width: 1450px) {
                    padding: 15px;
                }
            }
            &.show {
                border: 1px solid #efefef;
            }
        }
    }
}
