.search-container{
    position: relative;
    i,svg{
        position: absolute;
        top:50%;
        right:20px;
        transform: translateY(-50%);
        color:$color-orange;
    }
    input{
        border-radius: 50px;
        padding-left: 35px;
        padding-right: 35px;
    }
    &-mobile {
        @media screen and (min-width: 1500px) {
            display: none !important;
        }
    }
    &-desktop {
        @media screen and (max-width: 1500px) {
            display: none !important;
        }
    }
}