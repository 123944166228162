.matching-detail-item {
    margin-bottom: 30px;
    &-title {
        font-size: 16px;
        font-weight: 700;
        color: $primary;
        display: inline-block;
        border-bottom: 1px solid rgba($color-light-blue, 0.35);
        margin-bottom: 15px;
        @media screen and (min-width: 992px) and (max-width: 1450px) {
            font-size: 14px;
        }
    }
    &-content {
        .table {
            td {
                border-top: 0;
                border-bottom: 1px solid #dee2e6;
                vertical-align: middle;
                &:first-of-type {
                    padding-left: 0;
                }
            }
        }
        &-author {
            display: flex;
            align-items: center;
            font-weight: 700;
        }
        a,
        span.link,
        .location {
            display: flex;
            font-weight: 700;
            margin-bottom: 10px;
            align-items: center;
            i,
            svg {
                color: $color-orange;
                margin-right: 20px;
                font-size: 22px;
                @media screen and (min-width: 992px) and (max-width: 1450px){
                    font-size: 16px;
                }
            }
        }
        a {
            cursor: pointer;
            transition: 0.3s ease all;
            &:hover {
                color: $color-orange;
            }
        }
        .badge {
            a {
                margin: 0;
                color: white;
            }
        }
    }
}
