.calendar-view {
    @media screen and (max-width: 992px) {
        .fc table {
            font-size: 14px;
        }
        // .fc-view {
        //     min-height: 600px;
        // }
    }
    .fc-daygrid-event {
        span {
            &:not(.badge) {
                display: inline-block;
            }
        }
    }

    .fc-header-toolbar {
        @media screen and (max-width: 992px) {
            flex-wrap: wrap;
            .fc-toolbar-chunk {
                width: 100%;
                flex: 0 0 100%;
                max-width: 100%;
                button {
                    font-size: 13px;
                }
                .fc-toolbar-title {
                    margin: 10px 0;
                    font-size: 24px;
                }
            }
        }
    }

    .event-sub-row {
        justify-content: center;
        align-items: start;
        flex-wrap: wrap;
        display: flex;
        justify-content: flex-start;
    }

    .fc-daygrid-event {
        display: flex;
        white-space: normal;
    }

    .fc-title {
        word-break: break-word;
    }

    .fc-day,
    .fc-list {
        background: white;
    }

    .fc-direction-ltr .fc-button-group > .fc-button:first-child {
        border-top-left-radius: 25px !important;
        border-bottom-left-radius: 25px !important;
    }
    .fc-direction-ltr .fc-button-group > .fc-button:last-child {
        border-top-right-radius: 25px !important;
        border-bottom-right-radius: 25px !important;
    }
    .fc-customToday-button {
        border-radius: 25px !important;
    }

    .fc-list-event-time,
    .fc-list-event-graphic {
        display: none !important;
    }

    .fc-list-event-title {
        &:hover {
            color: black;
        }
    }

    .fc-daygrid-event {
        &.online-event {
            background: rgba($color-orange, 0.25) !important;
            border: 0 !important;
            color: black !important;
            margin-bottom: 2px !important;
        }
        &.hybrid-event {
            background: rgba($primary, 0.25) !important;
            border: 0 !important;
            color: black !important;
            margin-bottom: 2px !important;
        }
        &.other-event {
            background: rgba($color-light-blue, 0.25) !important;
            border: 0 !important;
            color: black !important;
            margin-bottom: 2px !important;
        }
        .fc-event-main {
            color: black;
        }
        .event-sub-row {
            .badge-secondary {
                background-color: rgba(white, 0.85);
            }
        }
    }
}

.calendar-popover {
    @media screen and (max-width: 1400px) {
        font-size: 12px !important;
        pre {
            font-size: 12px !important;
        }
    }
}

.calendar-legende {
    &-badge {
        padding: 4px 8px;
        border-radius: 4px;
        color: black;
        display: inline-block;
        margin-right: 10px;
        font-size: 14px;
        &.online {
            background: rgba($color-orange, 0.25);
        }
        &.vorort {
            background: rgba($color-light-blue, 0.25);
        }
        &.hybrid {
            background: rgba($primary, 0.25);
        }
    }
}
