.media-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 3%;
    &-item {
        flex: 0 0 calc(93% / 3);
        max-width: calc(93% / 3);
        border: 1px solid #00b3c3;
        background-color: #cceaf0;
        margin-bottom: 13px;
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        background-size: cover;
        background-position: center;
        background-image: url(../img/ressort.png);
        cursor: pointer;
        &:after {
            content: "";
            display: block;
            padding-top: 100%;
        }
        i,
        svg {
            top: 30%;
            left: 50%;
            font-size: 22px;
            color: $primary;
            position: absolute;
            transform: translate(-50%, -50%);
        }
        &-name{
            position: absolute;
            bottom:5px;
            width:90%;
            left:50%;
            transform:translateX(-50%);
            font-size: 10px;
            text-align: center;
        }
        &.file {
            background-image: none;
        }
    }
    &-header {
        flex: 0 0 100%;
        max-width: 100%;
        font-weight: 700;
        margin-bottom:10px;
        font-size: 18px;
    }
}
