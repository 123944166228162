.unauthorized-page{
    max-width: 80%;
    margin:0 auto;
    margin-top:75px;
    @media screen and (max-width:1600px){
        max-width:90%;
    }
    @media screen and (max-width:1500px){
        max-width:98%;
    }
    .custom-checkbox{
        a{
            transition: 0.3s ease all;
            font-weight: 600;
            &:hover{
                color:$color-orange;
            }
        }
    }
}