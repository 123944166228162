.workspace-file {
    &-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 2%;
    }
    flex: 0 0 calc((100% - (4 * 2%)) / 5);
    max-width: calc((100% - (4 * 2%)) / 5);
    background-color: white;
    box-shadow: 0 0 12px 0 rgba(black, 0.2);
    border-radius: 10px;
    margin-bottom: 30px;
    overflow: hidden;
    @media screen and (min-width: 1400px) and (max-width: 1900px) {
        flex: 0 0 calc((100% - (3 * 2%)) / 4);
        max-width: calc((100% - (3 * 2%)) / 4);
    }
    @media screen and (min-width:992px) and (max-width:1399px){
        flex: 0 0 calc((100% - (2 * 2%)) / 3);
        max-width: calc((100% - (2 * 2%)) / 3);
    }
    @include media-breakpoint-only(md){
        flex: 0 0 calc((100% - (2 * 2%)) / 3);
        max-width: calc((100% - (2 * 2%)) / 3);
    }
    @include media-breakpoint-down(sm){
        flex: 100%;
        max-width: 100%;
    }
    &-preview {
        height: 150px;
        position: relative;
        overflow: hidden;
        .workspace-file-marker {
            display: inline-block;
            color: white;
            background-color: $color-orange;
            text-align: center;
            padding: 4px 30px;
            font-size: 10px;
            font-weight: 500;
            position: absolute;
            top: 0;
            left: 0;
            transform: rotate(-45deg);
            top: 25px;
            left: -30px;
        }
        &-image {
            background-color: lightgray;
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        &-icon {
            height: 100%;
            width: 100%;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $primary;
            font-size: 40px;
        }
    }
    &-content {
        padding: 15px 20px;
    }
    &-title {
        color: $primary;
        text-align: center;
        font-weight: 500;
        margin-bottom: 15px;
    }
    &-row {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 2%;
        .workspace-file-badge {
            flex: 0 0 70%;
            max-width: 70%;
            @media screen and (max-width: 1900px) {
                flex: 0 0 100%;
                max-width: 100%;
            }
            .badge {
                font-size: 10px;
                color: $primary;
                background-color: rgba(lightgray, 0.6);
                font-weight: 400;
                padding: 4px 10px;
                text-transform: none;
            }
        }
        .workspace-file-actions {
            flex: 0 0 28%;
            max-width: 28%;
            text-align: right;
            @media screen and (max-width: 1900px) {
                flex: 0 0 100%;
                max-width: 100%;
                text-align: left;
                margin-top: 5px;
            }
            span {
                margin-left: 8px;
                color: $color-orange;
                font-size: 12px;
            }
        }
    }
}
