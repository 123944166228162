body:not(.modal-xl-default) {
    .modal-dialog {
        &.modal-xl {
            .modal-content {
                border-radius: 0;
                border: 0;
                .modal-header {
                    padding: 0;
                    border: 0;
                    position: relative;
                    button {
                        position: absolute;
                        width: 65px;
                        height: 65px;
                        top: 0;
                        right: -65px;
                        background-color: #efefef;
                        z-index: 2000;
                        opacity: 1;
                        color: red;
                        font-weight: 400;
                        font-size: 45px;
                        margin: 0;
                        padding: 0;
                        transition: 0.3s ease all;
                        @include media-breakpoint-down(md) {
                            right: 10px;
                            top: 10px;
                            height: auto;
                            width: auto;
                            background-color: transparent;
                        }
                        &:hover {
                            opacity: 1;
                            background-color: red;
                            color: white;
                        }
                    }
                }
                .modal-body {
                    padding: 45px 55px;
                    @include media-breakpoint-down(md) {
                        padding: 25px;
                    }
                }
            }
        }
    }
}
