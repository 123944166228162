.survey-list {
    gap: 1.5%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    @include media-breakpoint-down(sm) {
        gap: 0;
    }
    &-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 0 0 calc(95.5% / 4);
        max-width: calc(95.5% / 4);
        margin-bottom: 25px;
        box-shadow: 0 0 13px 0 rgba(black, 0.2);
        padding: 15px;
        border-radius: 10px;
        background-color: white;
        @media screen and (min-width: 1200px) and (max-width: 1560px) {
            flex: 0 0 calc(97% / 3);
            max-width: calc(97% / 3);
        }
        @include media-breakpoint-only(md) {
            flex: 0 0 calc(98.5% /2);
            max-width: calc(98.5% /2);
        }
        @include media-breakpoint-down(sm) {
            flex: 0 0 100%;
            max-width: 100%;
        }
        &-title {
            font-weight: 700;
            font-size: 18px;
            margin-bottom: 15px;
        }
    }
}
