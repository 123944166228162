.guest-layout{
    &>.navbar{
        background-color: #f7f7f7;
        box-shadow: $shadow-default;
    }
    .router-wrapper{
        @include media-breakpoint-up(lg){
            height: calc(100vh - 105px);
        }
    }
}