.survey-item-box {
    border: 1px solid rgba($primary, 0.35);
    padding: 15px 20px;
}

.survey-show{
    .survey-item-box {
        border: 0;
        padding: 0;
    }
}