$primary: #00495a;
$secondary: #cceaf0;
$success: #7fd913;
$primary-light: #d3e5f1;

$bg-color: #f7fdff;
$font-family-sans-serif: "Montserrat", sans-serif;
$font-size-base: 1rem;

$shadow-default: 0px 6px 8px 0px rgba(0, 0, 0, 0.09);

// Headline Size
$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.65;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.1;
$h6-font-size: $font-size-base;

$color-red: #b70000;
$color-yellow: #ffb55f;
$color-green: #66a304;
$color-orange: #ef5844;
$color-light-blue: #00b3c3;

$custom-control-indicator-checked-bg: $color-light-blue;
// $custom-checkbox-indicator-indeterminate-bg:$color-light-blue;
// $custom-checkbox-indicator-indeterminate-color:$color-light-blue;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1441px,
);

$header-height: 158px;
