.group-members {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 10px;
    &-list {
        & > div {
            font-size: 18px;
        }
    }
    .group-member {
        padding-left: 23px;
        position: relative;
        i,
        svg {
            position: absolute;
            top: 2px;
            left: 0;
        }
    }
}
