.company-admin-show {
    .small-speaker-list {
        display: none;
    }
    @media screen and (min-width: 992px) and (max-width: 1500px) {
        .list-header {
            margin-bottom: 10px;
        }
        .col-lg-9 {
            flex: 0 0 100%;
            max-width: 100%;
        }
        &>.col-lg-3 {
            display: none;
        }
        .small-speaker-list {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            gap: 30px;
            margin-bottom: 20px;
            &-item {
                position: relative;
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                align-items: center;
                gap: 15px;
                height: 35px;
                background-color: white;
                padding-right: 25px;
                border-radius: 40px;
                // .avatar {
                //     margin-right: 20px;
                // }
                &-name {
                    color: $primary;
                    font-weight: 500;
                }
                &-action {
                    i,
                    svg {
                        color: $color-orange;
                        margin-right: 7px;
                    }
                }
            }
        }
    }
}
