.matching-result-item {
    background-color: white;
    padding: 30px 35px;
    border-radius: 19px;
    box-shadow: $shadow-default;
    color: $primary;
    margin-bottom: 25px;
    &.highlighted {
        border: 1px solid darken(#f1f9fb, 25%);
    }
    @include media-breakpoint-down(lg) {
        padding: 25px 30px;
    }
    &-title {
        margin-bottom: 20px;
        font-weight: 700;
        font-size: 22px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        line-height: 1.25;
        @media screen and (min-width: 992px) and (max-width: 1450px) {
            font-size: 18px;
        }
        @include media-breakpoint-down(lg) {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 15px;
            flex-wrap: wrap;
            a {
                display: block;
                margin-top: 10px;
                flex: 0 0 100%;
            }
        }
        .radial-progress-container {
            display: inline-block;
            margin-right: 25px;
            .progress-count {
                font-size: 11px;
                margin: 0;
            }
        }
    }
    &-badges {
        margin-bottom: 15px;
        .badge {
            color: $primary;
            text-transform: none;
            @include media-breakpoint-down(lg) {
                margin-bottom: 10px;
            }
            i,
            svg {
                color: $color-orange;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    &-logo {
        max-width: 120px;
        margin: 0 auto;
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
}
