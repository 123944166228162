.read-more{
    &>span{
        display: block;
        text-align: center;
        position: relative;
        font-size: 16px;
        font-weight: 500;
        @media screen and (min-width: 992px) and (max-width: 1450px) {
          font-size: 14px;
        }
        i,svg{
            margin-left:7px;
            color:$color-orange;
        }
        &:before{
            content:'';
            display: inline-block;
            height:1px;
            background-color: $color-orange;
            width:35.5%;
            position: absolute;
            left:0;
            top:50%;
            transform: translateY(-50%);
            @media screen and (min-width: 992px) and (max-width: 1600px) {
                width:28%;
            }
            @include media-breakpoint-down(md){
                width:20%;
            }
        }
        &:after{
            content:'';
            display: inline-block;
            height:1px;
            background-color: $color-orange;
            width:35.5%;
            position: absolute;
            right:0;
            top:50%;
            transform: translateY(-50%);
            @media screen and (min-width: 992px) and (max-width: 1600px) {
                width:28%;
            }
            @include media-breakpoint-down(md){
                width:20%;
            }
        }
    }
    .read-more-actions{
        a{
            text-decoration: none !important;
        }
    }
}