.action-form{
    margin-top:30px;
    &-section{
        margin-bottom: 30px;
        color:$primary;
        &-header{
            font-size: 24px;
            font-weight: 400;
            margin-bottom: 20px;
            @media screen and (min-width: 992px) and (max-width: 1450px) {
              font-size: 20px;
            }
        }
        h3{
            @media screen and (min-width:992px) and (max-width:1450px){
                font-size: 20px;
            }
        }
    }
}