.detail-view {
    margin-bottom: 35px;
    color: $primary;
    &-header {
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 20px;
    }
    &-section {
        margin-bottom: 20px;
    }
    &-label {
        margin-bottom: 5px;
    }
    &-value {
        font-weight: 700;
    }
    &:last-of-type{
        margin-bottom: 0;
    }
}
