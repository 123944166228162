.card-deck {
  margin-bottom: 35px;
  @include media-breakpoint-down(lg) {
    max-width: 100%;
    .card {
      flex: 0 0 100%;
      margin-bottom: 25px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.card {
  border: 0;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(black, 0.15);
  @include media-breakpoint-down(md) {
    border-radius: 12px;
  }
  .card-header {
    overflow: hidden;
    color: black;
    font-size: 18px;
    font-weight: 700;
    background-color: #ededed;
    padding: 20px 1.25rem;
    line-height: 1;
    border: 0;
    border-radius: calc(20px - 1px) calc(20px - 1px) 0 0;
    @media screen and (min-width: 992px) and (max-width: 1450px) {
      font-size: 14px;
    }
    @include media-breakpoint-down(md) {
      font-size: 16px;
      font-weight: 600;
      padding: 15px 1.25rem;
      border-radius: 12px 12px 0 0;
    }
  }
  .card-body {
    position: relative;
    .blur {
      background-color: rgba(white, 0.15);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 0 0 calc(20px - 1px) calc(20px - 1px);
      filter: blur(8px);
      display: none;
    }
  }
  .card-footer {
    border-radius: 0 0 calc(20px - 1px) calc(20px - 1px);
  }
  &.no-inner-padding {
    .card-body {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &.filter-card {
    .card-header {
      background-color: #cceaf0;
      font-weight: 400;
      font-size: 16px;
      .form-group {
        margin: 0;
      }
      .col-lg-2{
        &>label{
          line-height: 24px;
        }
      }
    }
  }
}

.card {
  &.blured {
    .blur {
      display: block;
    }
  }
}
