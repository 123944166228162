.ps {
  max-height: 100%;
  height: 100%;
  .ps-h230 {
    height: 230px;
  }
}

.ps-h230 {
  height: 230px;
}
.ps-h320 {
  height: 320px;
}
.ps-h400 {
  height: 400px;
}
