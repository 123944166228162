.company-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap:2%;
  margin-bottom: 25px;
  &-item{
    flex: 0 0 23.5%;
    max-width:23.5%;
    background-color: white;
    box-shadow: 0 0 15px 0 rgba(black,0.2);
    padding:15px;
  }
}
