.ressort-header {
  height: 300px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 90px;
  @include media-breakpoint-down(md) {
    height: auto;
    padding-top: 50px;
    padding-bottom: 25px;
    margin-bottom: 30px;
  }
  &-effect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255);
    background: linear-gradient(45deg, rgba(#f1f9fb, 1) 15%, rgba(255, 255, 255, 0) 70%);
  }
  &-inner {
    width: 100%;
    position: relative;
    padding-left: 5%;
    padding-right: 5%;
    z-index: 5;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    @include media-breakpoint-down(md) {
      .float-right {
        float: none !important;
        margin-top: 15px;
        .btn {
          padding: 9px 19px;
        }
      }
    }
  }
  &-image {
    flex: 0 0 130px;
    max-width: 130px;
    @include media-breakpoint-down(lg){
      flex: 0 0 100%;
      max-width: 100%;
    }
    &-circle {
      width: 100px;
      height: 100px;
      display: block;
      border-radius: 50%;
      background-color: white;
      box-shadow: $shadow-default;
      position: relative;
      img {
        max-width: 85%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &-container {
    flex: 0 0 100%;
    max-width: 100%;
    &:not(.big) {
      flex: 0 0 calc(100% - 130px);
      max-width: calc(100% - 130px);
      @include media-breakpoint-down(lg){
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  h1 {
    font-weight: 700;
    color: $primary;
    font-size: 34px;
    @media screen and (min-width: 992px) and (max-width: 1450px) {
      font-size: 28px;
    }
    @include media-breakpoint-down(md) {
      font-size: 24px;
    }
  }
  .badge:not(.badge-danger) {
    background-color: $color-light-blue;
    text-transform: none;
    font-size: 12px;
    font-weight: 700;
    white-space: break-spaces;
    word-break: break-all;
    @media screen and (min-width: 1200px) and (max-width: 1560px) {
      max-width: 70%;
    }
  }
  .btn {
    background-color: $color-orange;
    color: white;
    border: 1px solid $color-orange;
    font-size: 14px;
    font-weight: 600;
    @media screen and (min-width: 992px) and (max-width: 1450px) {
      font-size: 12px;
    }
    i,
    svg {
      margin-right: 10px;
    }
    &:hover,
    &:focus {
      background-color: $primary;
      color: white;
      border-color: $primary;
    }
  }
}
.ressort-container {
  max-width: 90%;
  margin: 0 auto;
  margin-top: -58px;
  @include media-breakpoint-down(md) {
    margin-top: 0;
  }
  .card {
    .card-text {
      color: $primary;
      font-size: 16px;
      @media screen and (min-width: 992px) and (max-width: 1450px) {
        font-size: 14px;
      }
      @include media-breakpoint-down(md) {
        font-size: 15px;
        line-height: 25px;
      }
    }
  }
}
