.global-breadcrumb {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 0 0 80%;
  @include media-breakpoint-down(lg) {
    display: none;
  }
  &-wrapper {
    background-color: $secondary;
    padding-right: 1rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .breadcrumb-step {
    flex: 0 0 auto;
    padding: 6px 0.8rem;
    color: $primary;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    text-decoration: none;
    transition: 0.3s ease all;
    position: relative;
    padding-left: 18px;
    display: flex;
    align-items: center;
    @media screen and (min-width: 1441px) and (max-width: 1635px) {
      font-size: 10px;
      padding-left: 1rem;
    }
    @media screen and (min-width: 992px) and (max-width: 1440px) {
      font-size: 9px;
      line-height: 1.1;
      padding-left: 15px;
    }
    &:before {
      content: "";
      width: 1em;
      background: $secondary;
      position: absolute;
      bottom: 0;
      top: 0;
      left: 100%;
      z-index: 1;
      clip-path: polygon(50% 50%, -50% -50%, 0 100%);
      transition: background 0.2s linear;
    }
    i,
    svg {
      color: $color-orange;
      margin-right: 12px;
      transition: 0.3s ease all;
      margin-top: -2px;
    }
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 440px;
      display: inline-block;
    }
    &:last-child {
      background-color: $color-light-blue;
      color: white;
      i,
      svg {
        color: white;
      }
      &:before {
        background: $color-light-blue;
      }
    }
    &:hover {
      background-color: $color-light-blue;
      color: white;
      i,
      svg {
        color: white;
      }
      &:before {
        background: $color-light-blue;
      }
    }
  }
  &-side {
    flex: 0 0 20%;
    text-align: right;
    font-size: 12px;
    line-height: 1;
    @media screen and (min-width: 992px) and (max-width: 1300px) {
      font-size: 10px;
    }
    @include media-breakpoint-down(lg) {
      flex: 0 0 100%;
    }
    span {
      padding: 8px 0;
      display: inline-block;
      margin-right: 10px;
    }
    .btn {
      padding: 8px 0;
      font-size: 12px;
      line-height: 1;
      background-color: transparent;
      box-shadow: none;
      border: 0;

      float: right;
      margin-left: 20px;
      @media screen and (min-width: 992px) and (max-width: 1300px) {
        font-size: 10px;
      }
      &:hover,
      &:focus,
      &:target {
        background-color: transparent;
        box-shadow: none;
        border: 0;
      }
    }
  }
}
