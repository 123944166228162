.thread-view {
  &-card {
    margin-top: 15px;
    .card-body {
      padding: 30px 40px;
      @media screen and (min-width: 992px) and (max-width: 1450px) {
        padding: 30px;
      }
      @include media-breakpoint-down(md) {
        padding: 20px;
      }
    }
    h1 {
      color: $primary;
      font-weight: 700;
      font-size: 28px;
      margin-bottom: 25px;
      @media screen and (min-width: 992px) and (max-width: 1450px) {
        font-size: 22px;
      }
      @include media-breakpoint-down(md) {
        font-size: 20px;
      }
    }
    &-data {
      font-size: 14px;
      color: grey;
      margin-bottom: 25px;
      i,
      svg {
        color: $color-orange;
        margin-right: 10px;
      }
    }
    &-content {
      color: $primary;
      font-size: 16px;
      line-height: 24px;
      @media screen and (min-width: 992px) and (max-width: 1450px) {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  &-comments {
    &-title {
      margin-bottom: 15px;
      color: $primary;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      &-count {
        font-weight: 700;
        font-size: 21px;
        @media screen and (min-width: 992px) and (max-width: 1450px) {
          font-size: 18px;
        }
        @include media-breakpoint-down(md) {
          font-size: 18px;
          font-weight: 600;
        }
      }
      .sort-comments {
        font-size: 12px;
        font-weight: 400;
        & > span {
          margin-left: 10px;
          cursor: pointer;
          i,
          svg {
            color: $color-orange;
          }
        }
        & > div {
          display: inline-block;
        }
      }
    }
    &-content {
    }
  }
  &-card-detail {
    &-item {
      margin-bottom: 15px;
      font-size: 18px;
      @media screen and (min-width: 992px) and (max-width: 1560px) {
        margin-bottom: 10px;
      }
      &-title {
        color: $primary;
        font-size: 16px;
        display: inline-block;
        border-bottom: 1px solid rgba($color-light-blue, 0.2);
        margin-bottom: 10px;
        @media screen and (min-width: 992px) and (max-width: 1560px) {
          margin-bottom: 5px;
          font-size: 14px;
        }
      }
      &-content {
        color: $primary;
        font-size: 16px;
        @media screen and (min-width: 992px) and (max-width: 1450px) {
          font-size: 14px;
        }
        i,
        svg {
          margin-right: 10px;
          color: $color-orange;
          transition: 0.3s ease all;
        }
        &.author {
          display: flex;
          align-items: center;
          .avatar {
            margin-right: 10px;
            &.small {
              flex: 0 0 35px;
              max-width: 35px;
              @media screen and (min-width: 992px) and (max-width: 1500px) {
                flex: 0 0 28px;
                max-width: 28px;
              }
            }
          }
        }
        &.author + .author{
          margin-top: .5rem;
        }
        &.link {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          @media screen and (min-width: 992px) and (max-width: 1450px) {
            font-size: 14px;
          }
          svg {
            flex: 0 0 auto;
          }
          b {
            flex: 1 1 auto;
            max-width: 90%;
            @media screen and (min-width: 992px) and (max-width: 1450px) {
              font-size: 12px;
            }
            @include media-breakpoint-down(md) {
              font-size: 12px;
            }
          }
        }
        &.file {
          display: flex;
          align-items: center;
          cursor: pointer;

          @media screen and (min-width: 992px) and (max-width: 1450px) {
            font-size: 12px;
          }
          i,
          svg {
            margin-left: 15px;
          }
        }
      }
      &.half-item {
        width: 50%;
        float: left;
        @media screen and (min-width: 1200px) and (max-width: 1500px) {
          width: 100%;
          float: none;
        }
        @include media-breakpoint-down(md) {
          width: 100%;
          float: none;
        }
      }
      .btn:not(.btn-success) {
        border-color: $color-orange;
        color: $color-orange;
        background-color: transparent;
        font-weight: 600;
        font-size: 14px;
        i,
        svg {
          margin-left: 10px;
        }
        &:hover {
          background-color: $color-orange;
          color: white;
          i,
          svg {
            color: white;
          }
        }
      }
      .btn-success {
        i,
        svg {
          color: white;
        }
      }
    }
  }
  .sticky-top {
    padding-top: 15px;
    @include media-breakpoint-down(lg) {
      position: relative;
      z-index: 1;
    }
  }
}
.topic-show .card {
  padding: 20px;
}
